// Icons.js

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import HomeIcon from "@mui/icons-material/Home";
import SettingsIcon from "@mui/icons-material/Settings";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import InfoIcon from "@mui/icons-material/Info";
import MailIcon from "@mui/icons-material/Mail";
import LockIcon from "@mui/icons-material/Lock";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import FavoriteIcon from "@mui/icons-material/Favorite";
import StarIcon from "@mui/icons-material/Star";
import GridViewIcon from "@mui/icons-material/GridView";
import ListIcon from "@mui/icons-material/List";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import PhoneIcon from "@mui/icons-material/Phone";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import UploadIcon from "@mui/icons-material/Upload";
import DownloadIcon from "@mui/icons-material/Download";
import FilterListIcon from "@mui/icons-material/FilterList";
import SortIcon from "@mui/icons-material/Sort";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import DirectionsIcon from "@mui/icons-material/Directions";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import FlightLandIcon from "@mui/icons-material/FlightLand";
import TrainIcon from "@mui/icons-material/Train";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import MoodIcon from "@mui/icons-material/Mood";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentNeutralIcon from "@mui/icons-material/SentimentNeutral";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import TaskIcon from "@mui/icons-material/Task";
import EventIcon from "@mui/icons-material/Event";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import EventNoteIcon from "@mui/icons-material/EventNote";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import AddAlarmIcon from "@mui/icons-material/AddAlarm";
import AlarmIcon from "@mui/icons-material/Alarm";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import RepeatIcon from "@mui/icons-material/Repeat";
import SnoozeIcon from "@mui/icons-material/Snooze";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ShareIcon from "@mui/icons-material/Share";
import TelegramIcon from "@mui/icons-material/Telegram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";

import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import DoneIcon from "@mui/icons-material/Done";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ReceiptIcon from "@mui/icons-material/Receipt";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import ApprovalIcon from "@mui/icons-material/Approval";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import ModeEditIcon from "@mui/icons-material/ModeEdit";

import PrintIcon from "@mui/icons-material/Print";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { ArrowBack } from "@mui/icons-material";
import PreviewIcon from "@mui/icons-material/Preview";

import EditNoteIcon from "@mui/icons-material/EditNote";
import LoginIcon from "@mui/icons-material/Login";
import BadgeIcon from "@mui/icons-material/Badge";
import DescriptionIcon from "@mui/icons-material/Description";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import AttachFileIcon from "@mui/icons-material/AttachFile";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ClearIcon from "@mui/icons-material/Clear";
import CallIcon from "@mui/icons-material/Call";

const Icons = {
  ModeEditIcon: ModeEditIcon,
  RequestQuoteIcon: RequestQuoteIcon,
  ApprovalIcon: ApprovalIcon,
  DoneAllIcon: DoneAllIcon,
  ReceiptIcon: ReceiptIcon,
  ThumbDownOffAltIcon: ThumbDownOffAltIcon,
  DoneIcon: DoneIcon,
  RotateLeftIcon: RotateLeftIcon,
  CheckBoxIcon: CheckBoxIcon,
  HourglassEmptyIcon: HourglassEmptyIcon,
  PauseCircleOutlineIcon: PauseCircleOutlineIcon,
  Add: AddIcon,
  PrintIcon: PrintIcon,
  EditIcon: EditIcon,
  DeleteIcon: DeleteIcon,
  PictureAsPdfIcon: PictureAsPdfIcon,
  UploadFileIcon: UploadFileIcon,
  Edit: EditIcon,
  Delete: DeleteIcon,
  ArrowBack: ArrowBack,
  PreviewIcon: PreviewIcon,
  EditNoteIcon: EditNoteIcon,
  FilterListIcon: FilterListIcon,
  LoginIcon: LoginIcon,
  BadgeIcon: BadgeIcon,
  DescriptionIcon: DescriptionIcon,
  PendingActionsIcon: PendingActionsIcon,
  AttachFileIcon: AttachFileIcon,
  CloudUploadIcon: CloudUploadIcon,
  ChangeCircleIcon: ChangeCircleIcon,
  WhatsAppIcon: WhatsAppIcon,
  ClearIcon: ClearIcon,
  SearchIcon: SearchIcon,
  CallIcon: CallIcon,

  Remove: RemoveIcon,
  Search: SearchIcon,
  Save: SaveIcon,
  Home: HomeIcon,
  Settings: SettingsIcon,
  AccountCircle: AccountCircleIcon,
  Info: InfoIcon,
  Mail: MailIcon,
  Lock: LockIcon,
  Visibility: VisibilityIcon,
  VisibilityOff: VisibilityOffIcon,
  ShoppingCart: ShoppingCartIcon,
  Favorite: FavoriteIcon,
  Star: StarIcon,
  GridView: GridViewIcon,
  List: ListIcon,
  ChevronLeft: ChevronLeftIcon,
  ChevronRight: ChevronRightIcon,
  ArrowUpward: ArrowUpwardIcon,
  ArrowDownward: ArrowDownwardIcon,
  Check: CheckIcon,
  Cancel: CancelIcon,
  EditOutlined: EditOutlinedIcon,
  Phone: PhoneIcon,
  KeyboardArrowDown: KeyboardArrowDownIcon,
  KeyboardArrowUp: KeyboardArrowUpIcon,
  AddCircle: AddCircleIcon,
  RemoveCircle: RemoveCircleIcon,
  ExpandMore: ExpandMoreIcon,
  ExpandLess: ExpandLessIcon,
  Upload: UploadIcon,
  Download: DownloadIcon,
  FilterList: FilterListIcon,
  Sort: SortIcon,
  FormatAlignLeft: FormatAlignLeftIcon,
  FormatAlignCenter: FormatAlignCenterIcon,
  FormatAlignRight: FormatAlignRightIcon,
  CalendarToday: CalendarTodayIcon,
  AccessTime: AccessTimeIcon,
  LocationOn: LocationOnIcon,
  Directions: DirectionsIcon,
  EmojiEmotions: EmojiEmotionsIcon,
  Mood: MoodIcon,
  SentimentSatisfied: SentimentSatisfiedIcon,
  SentimentNeutral: SentimentNeutralIcon,
  SentimentDissatisfied: SentimentDissatisfiedIcon,
  SentimentVeryDissatisfied: SentimentVeryDissatisfiedIcon,
  Task: TaskIcon,
  Event: EventIcon,
  EventAvailable: EventAvailableIcon,
  EventBusy: EventBusyIcon,
  EventNote: EventNoteIcon,
  AccessAlarm: AccessAlarmIcon,
  AddAlarm: AddAlarmIcon,
  Alarm: AlarmIcon,
  WatchLater: WatchLaterIcon,
  Repeat: RepeatIcon,
  Snooze: SnoozeIcon,
  FavoriteBorder: FavoriteBorderIcon,
  Share: ShareIcon,
  Telegram: TelegramIcon,
  Facebook: FacebookIcon,
  Twitter: TwitterIcon,
  Instagram: InstagramIcon,
  LinkedIn: LinkedInIcon,
  YouTube: YouTubeIcon,
  DirectionsWalk: DirectionsWalkIcon,
  DirectionsBike: DirectionsBikeIcon,
  DirectionsCar: DirectionsCarIcon,
  DirectionsBoat: DirectionsBoatIcon,
  AirplanemodeActive: AirplanemodeActiveIcon,
  FlightTakeoff: FlightTakeoffIcon,
  FlightLand: FlightLandIcon,
  Train: TrainIcon,
  DirectionsBus: DirectionsBusIcon,
};

export default Icons;
