import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
} from "@mui/material";
import config from "../../config";
import Layout from "../../utils/Layout";

const ViewFiles = ({ fileid: propFileid }) => {
  // Check if fileid is passed as a prop or from the URL params
  const { fileid: urlFileid } = useParams();
  const fileid = propFileid || urlFileid;

  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!fileid) {
      setError("No fileid provided.");
      return;
    }

    // Fetch files based on the fileid parameter
    const fetchFiles = async () => {
      setLoading(true);
      setError(null);

      try {
        const username = localStorage.getItem("username");
        const session_id = localStorage.getItem("session_id");

        if (!username || !session_id) {
          throw new Error("User is not authenticated");
        }

        const response = await axios.get(
          `${config.apiBaseUrl}/file/fetch_files.php`,
          {
            params: { fileid, username, session_id },
          }
        );

        if (response.data.success) {
          setFiles(response.data.files);
        } else {
          setError(response.data.message);
        }
      } catch (err) {
        setError("Error fetching files.");
      } finally {
        setLoading(false);
      }
    };

    fetchFiles();
  }, [fileid]);

  return (
    <Layout>
      <Box sx={{ padding: 3 }}>
        <Typography variant="h4" gutterBottom>
          Files for File ID: {fileid}
        </Typography>

        {loading && <Typography variant="h6">Loading files...</Typography>}
        {error && (
          <Typography variant="h6" color="error">
            {error}
          </Typography>
        )}

        <Grid container spacing={3} sx={{ marginTop: 3 }}>
          {files.length > 0 ? (
            files.map((file, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card>
                  <CardContent>
                    <Typography variant="h6">{file.filename}</Typography>
                    <Typography variant="body2" color="textSecondary">
                      {file.date}
                    </Typography>
                    <Typography variant="body1">{file.remarks}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))
          ) : (
            <Typography variant="h6">
              No files found for this file ID.
            </Typography>
          )}
        </Grid>

        {/* Optional: Add button to upload files */}
        <Button
          variant="contained"
          color="primary"
          sx={{ marginTop: 3 }}
          onClick={() => (window.location.href = "/upload")} // Redirect to your upload page
        >
          Upload Files
        </Button>
      </Box>
    </Layout>
  );
};

export default ViewFiles;
