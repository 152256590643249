import { useEffect, useState } from "react";
import axios from "axios";
import config from "../config";
import {
  Button,
  Input,
  Box,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  OutlinedInput,
} from "@mui/material";
import { useToast } from "./ToastContext";
import Layout from "./Layout";
import { useNavigate, useParams } from "react-router-dom";

export default function EmployeeBasicPay() {
  const [empList, setEmpList] = useState([]);
  const [basicPayData, setBasicPayData] = useState({});
  const [loading, setLoading] = useState(true); // Loading state
  const triggerToast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEmpNamesAndBasicPay = async () => {
      try {
        // Fetch employee list
        const empListResponse = await axios.post(
          `${config.apiBaseUrl}/employee/get_emp_list.php`,
          {
            username: localStorage.username,
            session_id: localStorage.session_id,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          },
        );

        if (empListResponse.data.success) {
          setEmpList(empListResponse.data.data);
        } else {
          triggerToast("error", empListResponse.data.message);
        }

        // Fetch basic pay values for employees
        const basicPayResponse = await axios.post(
          `${config.apiBaseUrl}/employee/fetch_basic_pay.php`,
          {
            username: localStorage.username,
            session_id: localStorage.session_id,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          },
        );

        if (basicPayResponse.data.success) {
          const basicPayMap = basicPayResponse.data.data.reduce(
            (acc, { empid, basicpay }) => {
              acc[empid] = basicpay;
              return acc;
            },
            {},
          );
          setBasicPayData(basicPayMap);
        } else {
          triggerToast("error", basicPayResponse.data.message);
        }
      } catch (error) {
        console.error("Error fetching employee data or basic pay:", error);
        triggerToast("error", "Failed to load employee data or basic pay.");
      } finally {
        setLoading(false); // Hide loading spinner once data is fetched
      }
    };

    fetchEmpNamesAndBasicPay();
  }, []);

  useEffect(() => {
    // Add the non-passive event listener to prevent wheel scroll modification on input fields
    const handleWheel = (e) => {
      e.preventDefault();
    };

    const inputs = document.querySelectorAll('input[type="number"]');
    inputs.forEach((input) => {
      input.addEventListener("wheel", handleWheel, { passive: false });
    });

    return () => {
      inputs.forEach((input) => {
        input.removeEventListener("wheel", handleWheel);
      });
    };
  }, []);

  const handleInputChange = (empid, value) => {
    setBasicPayData((prev) => ({
      ...prev,
      [empid]: value,
    }));
  };

  const handleSave = async () => {
    try {
      // Filter out entries with no basicpay
      const filteredPayload = Object.entries(basicPayData)
        .filter(([empid, basicpay]) => basicpay !== "" && basicpay !== null)
        .map(([empid, basicpay]) => ({
          empid,
          basicpay,
        }));

      if (filteredPayload.length === 0) {
        triggerToast("error", "No valid basic pay data to save.");
        return;
      }

      const response = await axios.post(
        `${config.apiBaseUrl}/employee/basic_pay.php`,
        {
          username: localStorage.username,
          session_id: localStorage.session_id,
          data: filteredPayload,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      if (response.data.success) {
        triggerToast("success", "Basic pay saved successfully!");
      } else {
        triggerToast("error", response.data.message);
      }
    } catch (error) {
      console.error("Error saving basic pay:", error);
      triggerToast("error", "Failed to save basic pay.");
    }
  };

  const tableRowStyle = (index) => ({
    height: 20, // Default height
    backgroundColor: index % 2 === 0 ? "#ffffff" : "#f5f5f5", // Alternate between white and light grey
  });

  const tableCellStyle = {
    padding: 2, // Common padding for TableCell
    height: "20px", // Default height for TableCell
  };

  const inputStyle = {
    height: "10px", // Adjust the height of the input field
    padding: "0", // Remove input padding to match the design
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Layout>
      <Container
        maxWidth="md"
        style={{
          overflow: "auto",
          maxHeight: "80vh",
          scrollbarWidth: "thin",
          scrollbarColor: "lightgrey transparent",
          padding: "16px",
          border: "1px solid #e0e0e0",
          borderRadius: "8px",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#ffffff",
        }}
      >
        <h2 className="text-xl font-bold mb-4">Employee Basic Pay</h2>

        {/* Show loading indicator or table */}
        {loading ? (
          <div className="flex justify-center items-center">
            <CircularProgress />
            <p className="ml-4">Loading employee data...</p>
          </div>
        ) : (
          <TableContainer
          // sx={{
          //   flexGrow: 1,
          //   height: "calc(70vh - 90px)",
          //   overflow: "auto",
          //   "&::-webkit-scrollbar": {
          //     width: "5px",
          //     height: "5px",
          //   },
          //   "&::-webkit-scrollbar-thumb": {
          //     backgroundColor: "rgba(0, 0, 0, 0.5)",
          //     borderRadius: "10px",
          //   },
          //   "&::-webkit-scrollbar-thumb:hover": {
          //     backgroundColor: "rgba(0, 0, 0, 0.7)",
          //   },
          // }}
          >
            <Table stickyHeader aria-label="Employee Basic Pay Table">
              <TableHead>
                <TableRow style={{ backgroundColor: "#f5f5f5" }}>
                  <TableCell>#</TableCell>
                  <TableCell>ID</TableCell>
                  <TableCell>Employee Name</TableCell>
                  <TableCell>Basic Pay</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {empList.map((emp, index) => (
                  <TableRow key={emp.id} style={tableRowStyle(index)}>
                    <TableCell style={tableCellStyle}>{index + 1}</TableCell>

                    <TableCell style={tableCellStyle}>{emp.id}</TableCell>
                    <TableCell style={tableCellStyle}>{emp.surname}</TableCell>
                 <TableCell style={{ ...tableCellStyle, width: "30%", padding: 5 }}>
  <Box
    sx={{
      border: "1px solid #ccc",
      borderRadius: "4px",
      padding: "4px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center", // Centers content horizontally
      minHeight: "36px", // Adjust height as needed
      backgroundColor: "#fff", // Ensure background matches design
    }}
  >
    <Input
      type="text"
      value={basicPayData[emp.id] || ""}
      onChange={(e) => handleInputChange(emp.id, e.target.value)}
      placeholder="Enter Basic Pay"
      disableUnderline
      fullWidth
      sx={{
        textAlign: "center", // Center text inside input
        fontSize: "14px", // Adjust as needed
      }}
      inputProps={{
        style: {
          height: "20px",
          padding: "4px",
          textAlign: "center", // Ensures text stays centered

        },
      }}
    />
  </Box>
</TableCell>

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
  </Container>

  <Box
    mt={2}
    display="flex"
    justifyContent="center"
    gap={2}
  >
    <Button
      type="button"
      variant="outlined"
      color="secondary"
      onClick={handleBack}
      style={{ width: "150px" }}
    >
      Cancel
    </Button>
    <Button
      type="submit"
      variant="contained"
      color="primary"
      onClick={handleSave}
      style={{ width: "150px" }}
      disabled={loading}
    >
      {loading ? "Saving..." : "Save"}
    </Button>
  </Box>
</Layout>
  );
}
