import React, { useState } from "react";
import {
  TextField,
  Grid,
  Button,
  Box,
  Typography,
  Container,
  Divider,
  MenuItem,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Layout from "../../utils/Layout";
import config from "../../config";
import { useToast } from "../../utils/ToastContext";

const AddSite = () => {
  const [formData, setFormData] = useState({
    site_name: "",
    site_location: "",
    site_map: "",
    site_amount: "",
    start_date: "",
    end_date: "",
    client_name: "",
    client_number: "",
    client_location: "",
    Description: "",
    Remarks: "",
    username: localStorage.username, // Retrieve from localStorage
    session_id: localStorage.session_id, // Retrieve from localStorage
  });

  const navigate = useNavigate();
  const triggerToast = useToast();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChange = (e) => {
    const { name, value } = e.target;

    let formattedValue = value;

    if (name === "site_name") {
      // Capitalize first letter of each word & allow numbers
      formattedValue = value
        .toLowerCase()
        .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize first letter of each word
    }

    if (name === "client_name") {
      // Capitalize first letter of each word & prevent numbers
      formattedValue = value
        .replace(/[^a-zA-Z\s]/g, "") // Remove numbers and special characters
        .toLowerCase()
        .replace(/\b\w/g, (char) => char.toUpperCase());
    }

    if (name === "site_location" || name === "client_location") {
      // Allow only letters and spaces
      formattedValue = value.replace(/[^a-zA-Z\s]/g, "");
    }

    if (name === "site_amount" || name === "client_number") {
      // Allow only numbers
      formattedValue = value.replace(/[^0-9]/g, "");
    }

    setFormData({
      ...formData,
      [name]: formattedValue,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      console.log("Data sent:", formData);
      const response = await axios.post(
        `${config.apiBaseUrl}/site/add_site.php`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      console.log("Response data:", response.data);

      if (response.data.success) {
        triggerToast("success", response.data.message); // Show success message
        navigate("/site");
      } else {
        triggerToast("error", response.data.message); // Show error message
      }
    } catch (error) {
      console.error("Error:", error);
      triggerToast("error", "Something went wrong!"); // General error
    }
  };

  // const handleBack = () => {
  //   navigate("/site");
  // };
  const handleBack = () => {
    navigate(-1); // Navigate to the previous page
  };

  return (
    <Layout>
      <Container maxWidth="md">
        {!isMobile && ( // Hide on mobile
          <Box display="flex" alignItems="center" mb={3}>
            <IconButton onClick={handleBack}>
              <ArrowBack />
            </IconButton>
            <Typography variant="h5" ml={1}>
              Add New Site
            </Typography>
          </Box>
        )}
        <Container
          maxWidth="md"
          style={{
            overflow: "auto",
            maxHeight: "80vh",
            scrollbarWidth: "thin",
            scrollbarColor: "lightgrey transparent",
            padding: "16px",
            border: "1px solid #e0e0e0",
            borderRadius: "8px",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            backgroundColor: "#ffffff",
          }}
        >
          <form onSubmit={handleSubmit}>
            {/* Personal Details Section */}
            <Box mb={4}>
              <Typography variant="h6" gutterBottom>
                Site Details
              </Typography>
              <Divider />
              <Box display="flex" flexDirection="column" gap={2} mb={2}>
                {/* Dropdown for Site Names */}
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      label="Site Name"
                      variant="outlined"
                      name="site_name"
                      value={formData.site_name}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Site Location"
                      variant="outlined"
                      name="site_location"
                      value={formData.site_location}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      label="Site Map"
                      variant="outlined"
                      name="site_map"
                      value={formData.site_map}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Total Value"
                      variant="outlined"
                      name="site_amount"
                      // type="number"
                      value={formData.site_amount}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      label="Start Date"
                      type="date"
                      variant="outlined"
                      name="start_date"
                      value={
                        formData.start_date ||
                        new Date().toISOString().split("T")[0]
                      }
                      onChange={handleChange}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="End Date"
                      type="date"
                      variant="outlined"
                      name="end_date"
                      value={formData.end_date || ""}
                      onChange={handleChange}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                </Grid>

                <Typography variant="h6" gutterBottom>
                  Client Details
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      label="Client Name"
                      variant="outlined"
                      name="client_name"
                      value={formData.client_name}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Client Number"
                      variant="outlined"
                      name="client_number"
                      type="tel"
                      value={formData.client_number}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      label="Client Location"
                      variant="outlined"
                      name="client_location"
                      value={formData.client_location}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Remarks"
                      variant="outlined"
                      name="remarks"
                      value={formData.remarks}
                      onChange={handleChange}
                      multiline
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Typography variant="h6" gutterBottom>
                  Other Details
                </Typography>
                <TextField
                  label="Description"
                  variant="outlined"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  multiline
                  rows={4}
                  fullWidth
                />
              </Box>
            </Box>

            {/* Buttons */}
            <Box mt={4} display="flex" justifyContent="space-between">
              <Button
                type="button"
                variant="outlined"
                color="secondary"
                onClick={handleBack}
                style={{ flex: 1, marginRight: "8px" }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{ flex: 1 }}
              >
                Save
              </Button>
            </Box>
          </form>
        </Container>
      </Container>
    </Layout>
  );
};

export default AddSite;
