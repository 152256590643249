import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Button,
  Box,
  Typography,
  Container,
  Divider,
  MenuItem,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Layout from "../../utils/Layout";
import config from "../../config";
import { useToast } from "../../utils/ToastContext";

const AddTransactions = () => {
  const [formData, setFormData] = useState({
    site_name: "",
    amount: "",
    date: new Date().toISOString().split("T")[0], // Default to current date
    remarks: "",
    username: localStorage.username, // Retrieve from localStorage
    session_id: localStorage.session_id, // Retrieve from localStorage
  });
  const [loading, setLoading] = useState(false); // Track loading state
  const navigate = useNavigate();
  const [siteList, setSiteList] = useState([]); // List of site names
  const triggerToast = useToast();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Fetch site names from the API
  useEffect(() => {
    const fetchSiteNames = async () => {
      try {
        const response = await axios.post(
          `${config.apiBaseUrl}/site/get_site_list.php`,
          {
            username: localStorage.username,
            session_id: localStorage.session_id,
          },
          {
            headers: {
              "Content-Type": " application/json",
            },
          },
        );

        if (response.data.success) {
          setSiteList(response.data.data); // Assuming the data is an array of { id, surname }
        } else {
          triggerToast("error", response.data.message); // Show error message
        }
      } catch (error) {
        console.error("Error fetching site names:", error);
        triggerToast("error", "Failed to load site names.");
      }
    };

    fetchSiteNames();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "amount") {
      // Allow only numbers with up to 3 decimal places
      if (!/^\d*\.?\d{0,3}$/.test(value)) return;
    }
  

    if (name === "date") {
      // Restrict year to four digits (YYYY-MM-DD format)
      if (!/^\d{0,4}(-\d{0,2}){0,2}$/.test(value)) return;
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!/^\d+(\.\d{1,3})?$/.test(formData.amount)) {
      triggerToast("error", "Amount should be a valid decimal number with up to three decimal places (e.g., 123, 123.456).");
      return;
    }
    
    

    if (!/^\d{4}-\d{2}-\d{2}$/.test(formData.date)) {
      triggerToast("error", "Invalid date format. Use YYYY-MM-DD.");
      return;
    }

    setLoading(true);
    try {
      console.log("Data sent:", formData);
      const response = await axios.post(
        `${config.apiBaseUrl}/transaction/add_transaction.php`,
        formData,
        {
          headers: { "Content-Type": "application/json" },
        },
      );

      if (response.data.success) {
        triggerToast("success", "Transaction added successfully!");
        navigate("/transaction");
      } else {
        triggerToast("error", response.data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      triggerToast("error", "Something went wrong!");
    } finally {
      setLoading(false);
    }
  };

  // const handleBack = () => {
  //   navigate("/transaction");
  // };

  const handleBack = () => {
    navigate(-1); // Navigate to the previous page
  };

  return (
    <Layout>
      <Container maxWidth="md">
        {!isMobile && ( // Hide on mobile
          <Box display="flex" alignItems="center" mb={3}>
            <IconButton onClick={handleBack}>
              <ArrowBack />
            </IconButton>
            <Typography variant="h5" ml={1}>
              Add New Transaction
            </Typography>
          </Box>
        )}

        <Container
          maxWidth="md"
          style={{
            overflow: "auto",
            maxHeight: "80vh",
            scrollbarWidth: "thin",
            scrollbarColor: "lightgrey transparent",
            padding: "16px",
            border: "1px solid #e0e0e0",
            borderRadius: "8px",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            backgroundColor: "#ffffff",
          }}
        >
          <form onSubmit={handleSubmit}>
            {/* Transaction Details Section */}
            <Box mb={4}>
              <Typography variant="h6" gutterBottom>
                Transaction Details
              </Typography>
              <Divider />
              <Box display="flex" flexDirection="column" gap={2} mb={2} mt={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Date"
                      type="date"
                      variant="outlined"
                      name="date"
                      value={formData.date}
                      onChange={handleChange}
                      required
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        pattern: "\\d{4}-\\d{2}-\\d{2}",
                        title: "Enter a valid date in YYYY-MM-DD format",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Amount"
                      variant="outlined"
                      name="amount"
                      type="text"
                      value={formData.amount}
                      onChange={handleChange}
                      required
                      fullWidth
                      inputProps={{
                        inputMode: "decimal",
                        pattern: "^[0-9]*\\.?[0-9]{0,3}$",
                        title: "Enter a valid amount (e.g., 123, 123.456)",
  }}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    {/* Dropdown for Site Names */}
                    <TextField
                      select
                      label="Site Name"
                      variant="outlined"
                      name="site_name"
                      value={formData.site_name}
                      onChange={handleChange}
                      required
                      fullWidth
                    >
                      {siteList.map((site) => (
                        <MenuItem key={site.id} value={site.id}>
                          {site.site_name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Remarks"
                      variant="outlined"
                      name="remarks"
                      value={formData.remarks}
                      onChange={handleChange}
                      multiline
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>

            {/* Buttons */}
            <Box mt={4} display="flex" justifyContent="space-between">
              <Button
                type="button"
                variant="outlined"
                color="secondary"
                onClick={handleBack}
                style={{ flex: 1, marginRight: "8px" }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{ flex: 1 }}
                disabled={loading} // Disable button when loading
              >
                {loading ? "Adding..." : "Save"}
              </Button>
            </Box>
          </form>
        </Container>
      </Container>
    </Layout>
  );
};

export default AddTransactions;
