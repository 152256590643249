import React, { useState, useEffect } from "react";
import { Button, Box, Typography, Paper, Grid, Modal } from "@mui/material";
import { SpeedDial, SpeedDialAction, Fab } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import html2pdf from "html2pdf.js";
import { useToast } from "../../utils/ToastContext";

import Layout from "../../utils/Layout";
import config from "../../config";
import { formatDate } from "../../utils/format";
import Icons from "../../utils/icon";
import LoadingPage from "../../utils/loading";

const SiteDetails = () => {
  const { id } = useParams();
  const [siteDetails, setSiteDetails] = useState({});
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [previewFile, setPreviewFile] = useState(null);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState(false);
  const triggerToast = useToast();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const requestData = {
          id,
          username: localStorage.getItem("username"),
          session_id: localStorage.getItem("session_id"),
        };

        console.log("Fetching Quote with ID:", id);
        const response = await axios.post(
          `${config.apiBaseUrl}/site/get_by_id_site.php`,
          requestData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.success) {
          console.log("Full response:", response);
          console.log("Response data:", response.data);

          const fetchedData = response.data.site;
          setFormData((prevData) => ({ ...prevData, ...fetchedData }));
          setSiteDetails(fetchedData);

          console.log(fetchedData);
        } else {
          triggerToast(
            "error",
            response.data.message || "Failed to fetch site data"
          );
        }
      } catch (error) {
        console.error("Error fetching site data:", error);
        triggerToast("error", "An error occurred while fetching site data.");
      } finally {
        setLoading(false); // Set loading to false after fetch is complete
      }
    };

    fetchData();
  }, [id]);

  const handleBack = () => {
    navigate(-1);
  };

  const handleEdit = () => {
    navigate(`/edit_site/${id}`);
  };

  const handlePrint = () => {
    const paperContainer = document.getElementById("print-area");
    if (paperContainer) {
      const printWindow = window.open("", "_blank");
      printWindow.document.write(
        "<html><head><title>Print</title><style>@page { size: A4; }</style></head><body>"
      );
      printWindow.document.write(paperContainer.innerHTML);
      printWindow.document.write("</body></html>");
      printWindow.document.close();
      printWindow.focus();
      printWindow.print();
      printWindow.close();
    }
  };

  const handleDelete = async () => {
    try {
      const deleteData = {
        id: id,
        username: localStorage.username,
        session_id: localStorage.session_id,
      };

      const response = await axios.post(
        `${config.apiBaseUrl}/delete_site.php`,
        deleteData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.success) {
        triggerToast("success", response.data.message);
        navigate("/site");
      } else {
        triggerToast("error", response.data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      triggerToast("error", "Something went wrong!");
    }
  };

  const getDeviceName = (userAgent) => {
    if (/Android/i.test(userAgent)) {
      return "Android Device";
    } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
      return "iOS Device";
    } else if (/Windows NT/i.test(userAgent)) {
      return "Windows PC";
    } else if (/Macintosh/i.test(userAgent)) {
      return "Mac";
    } else if (/Linux/i.test(userAgent)) {
      return "Linux Device";
    } else {
      return "Unknown Device";
    }
  };

  const userAgent = navigator.userAgent;
  const deviceInfo = getDeviceName(userAgent);

  const handleGeneratePDF = () => {
    const element = document.getElementById("site-report-container");
    const pdfContainer = document.createElement("div");
    pdfContainer.style.position = "relative";
    pdfContainer.style.width = "100%";

    const contentClone = element.cloneNode(true);
    // Create the footer
    const footer = document.createElement("div");
    footer.style.position = "relative";
    footer.style.bottom = "0";
    footer.style.width = "100%";
    footer.style.textAlign = "center";
    footer.style.fontSize = "12px";
    footer.style.padding = "10px 0";
    footer.style.borderTop = "1px solid #ccc";
    footer.innerHTML = `
      Created on: ${new Date().toLocaleDateString()} | System Info: ${
        localStorage.deviceInfo || "Unknown Device"
      } | Created by: ${localStorage.username} with session ${
        localStorage.session_id
      }
    `;

    // Append the content and footer to the container
    pdfContainer.appendChild(contentClone);
    pdfContainer.appendChild(footer);

    document.body.appendChild(pdfContainer);

    html2pdf()
      .from(pdfContainer)
      .set({
        margin: [10, 10, 10, 10],
        html2canvas: { scale: 2 },
        pagebreak: { mode: "avoid-all" },
      })
      .toPdf()
      .get("pdf")
      .then(function (pdf) {
        const fileName = `${id}_${siteDetails.site_name}.pdf`;
        pdf.save(fileName);
      })
      .finally(() => {
        document.body.removeChild(pdfContainer);
      });
  };

  const FloatingActionButtons = () => {
    return (
      <SpeedDial
        ariaLabel="Action Buttons"
        sx={{
          position: "absolute",
          bottom: 16,
          right: 16,
          zIndex: 1000,
          justifyContent: "end",
        }}
        icon={<Icons.Add />}
      >
        <SpeedDialAction
          icon={<Icons.Edit />}
          tooltipTitle="Edit"
          onClick={handleEdit}
        />
        <SpeedDialAction
          icon={<Icons.PrintIcon />}
          tooltipTitle="Print"
          onClick={handlePrint}
        />
        <SpeedDialAction
          icon={<Icons.DeleteIcon />}
          tooltipTitle="Delete"
          onClick={handleDelete}
        />
        <SpeedDialAction
          icon={<Icons.PictureAsPdfIcon />}
          tooltipTitle="Generate PDF"
          onClick={handleGeneratePDF}
        />
      </SpeedDial>
    );
  };

  const handleAdd = () => {
    navigate("/add_site");
  };

  const handlePreviewOpen = (file) => {
    setPreviewFile(file);
    setOpen(true);
  };

  const handlePreviewClose = () => {
    setOpen(false);
    setPreviewFile(null);
  };

  function FilePreviewButtons({ file, label, onClick }) {
    const [fileExists, setFileExists] = useState(true);

    useEffect(() => {
      const checkFileExists = async () => {
        if (file) {
          console.log(`Checking file: ${file}`);
          try {
            const response = await fetch(file, { method: "HEAD" });

            // Validate Content-Type for expected file types
            const contentType = response.headers.get("Content-Type");
            const validContentTypes = [
              "application/pdf",
              "image/jpeg",
              "image/png",
            ];
            const isValidFile = validContentTypes.includes(contentType);

            if (response.ok && isValidFile) {
              console.log(
                `File check for ${file} succeeded (Status: ${response.status})`
              );
              setFileExists(true);
            } else {
              console.log(
                `File check for ${file} failed: Invalid file or content type`
              );
              setFileExists(false);
            }
          } catch (error) {
            console.error(`File check for ${file} failed with error:`, error);
            setFileExists(false);
          }
        }
      };

      checkFileExists();
    }, [file]);

    return (
      <Button
        variant="outlined"
        onClick={onClick}
        disabled={!fileExists}
        startIcon={<Icons.Visibility />}
      >
        {label}
      </Button>
    );
  }

  return (
    <Layout>
      <Box
        id="print-area"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "start",
          maxHeight: "85vh",
          minHeight: "600px",
          overflow: "hidden",
        }}
      >
        <Paper
          id="site-report-container"
          sx={{
            padding: 3,
            width: "100%",
            maxWidth: 800,
            border: "0px solid black",
            boxSizing: "border-box",
            overflowY: "auto",
            overflowX: "auto",
          }}
        >
          <Box sx={{ position: "relative" }}>
            <Fab
              color="primary"
              size="small"
              onClick={handleBack}
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: 1000,
              }}
            >
              <Icons.ArrowBack />
            </Fab>
          </Box>
          {loading ? (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
              <LoadingPage />
            </Box>
          ) : (
            <Box>
              <Typography variant="h5" align="center" sx={{ mb: 2 }}>
                Site Report: {siteDetails?.site_name}
              </Typography>
              <Box sx={{ borderBottom: "1px solid #808080", mb: 2 }} />
              <Typography
                variant="body1"
                sx={{ display: "flex", justifyContent: "center", mb: 1 }}
              >
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={6}>
                    <Typography
                      variant="body1"
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: "bold", mr: 0.5 }}
                      >
                        Site Name:
                      </Typography>
                      <Typography variant="body1">
                        {siteDetails.site_name}
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="body1"
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: "bold", mr: 0.5 }}
                      >
                        Site Location:
                      </Typography>
                      <Typography variant="body1">
                        {siteDetails.site_location}
                      </Typography>
                    </Typography>
                  </Grid>
                  {/* </Grid>
              </Typography>
              <Box
                sx={{ borderBottom: "1px solid #808080", mb: 2, paddingTop: 1 }}
              />
              <Typography
                variant="body1"
                sx={{ display: "flex", justifyContent: "center", mb: 1 }}
              >
                <Grid container spacing={2} justifyContent="center"> */}
                  <Grid item xs={6}>
                    <Typography variant="body1" sx={{ display: "flex" }}>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: "bold", mr: 0.5 }}
                      >
                        Site Map:
                      </Typography>
                      <Typography variant="body1">
                        {siteDetails.site_map}
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" sx={{ display: "flex" }}>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: "bold", mr: 0.5 }}
                      >
                        Total Value:
                      </Typography>
                      <Typography variant="body1">
                        {siteDetails.site_amount}
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
              </Typography>
              {/* Added horizontal line here */}
              <Box
                sx={{ borderBottom: "1px solid #808080", mb: 2, paddingTop: 1 }}
              />
              <Typography variant="body1" sx={{ mb: 1 }}>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={6}>
                    <Typography variant="body1" sx={{ display: "flex" }}>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: "bold", mr: 0.5 }}
                      >
                        Start Date:
                      </Typography>
                      <Typography variant="body1">
                        {formatDate(siteDetails.start_date)} {config.currency}
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" sx={{ display: "flex" }}>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: "bold", mr: 0.5 }}
                      >
                        End Date:
                      </Typography>
                      <Typography variant="body1">
                        {formatDate(siteDetails.end_date)} {config.currency}
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
              </Typography>
              <Box sx={{ borderBottom: "1px solid #808080", my: 2 }} />
              <Typography variant="body1" sx={{ mb: 1 }}>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={6}>
                    <Typography variant="body1" sx={{ display: "flex" }}>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: "bold", mr: 0.5 }}
                      >
                        Client Name:
                      </Typography>
                      <Typography variant="body1">
                        {siteDetails.client_name}
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" sx={{ display: "flex" }}>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: "bold", mr: 0.5 }}
                      >
                        Client Number:
                      </Typography>
                      <Typography variant="body1">
                        {siteDetails.client_number}
                      </Typography>
                    </Typography>
                  </Grid>
                  {/* </Grid>
              </Typography>
              <Box sx={{ borderBottom: "1px solid #808080", my: 2 }} />
              <Typography variant="body1" sx={{ mb: 1 }}>
                <Grid container spacing={2} justifyContent="center"> */}
                  <Grid item xs={6}>
                    <Typography variant="body1" sx={{ display: "flex" }}>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: "bold", mr: 0.5 }}
                      >
                        Client Location:
                      </Typography>
                      <Typography variant="body1">
                        {siteDetails.client_location}
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" sx={{ display: "flex" }}>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: "bold", mr: 0.5 }}
                      >
                        Remarks:
                      </Typography>
                      <Typography variant="body1">
                        {siteDetails.remarks}
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
              </Typography>
              <Box
                sx={{
                  position: "relative",
                  borderBottom: "1px solid #808080",
                  my: 2,
                  textAlign: "center",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    position: "absolute",
                    top: "-10px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    backgroundColor: "white",
                    px: 1,
                    fontWeight: "bold",
                  }}
                >
                  Description
                </Typography>
              </Box>
              <Typography variant="body1" sx={{ mb: 1 }}>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={6}>
                    <Typography variant="body1" sx={{ display: "flex" }}>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: "bold", mr: 0.5 }}
                      >
                        {/* Description: */}
                      </Typography>
                      <Typography variant="body1">
                        {siteDetails.description}
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
              </Typography>
            </Box>
          )}
        </Paper>
        <FloatingActionButtons />
      </Box>
    </Layout>
  );
};
export default SiteDetails;
