import React, { useState, useEffect } from "react";
import {
  Button,
  Box,
  Typography,
  Paper,
  Grid,
  Grid2,
  Modal,
} from "@mui/material";
import { SpeedDial, SpeedDialAction, Fab } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import html2pdf from "html2pdf.js";
import { useToast } from "../../utils/ToastContext";

import Layout from "../../utils/Layout";
import config from "../../config";
import {
  formatDate,
  formatCardNumber,
  formatPhoneNumber,
  formatExpiry,
  getStatusBackgroundColor,
  getStatusLabel,
  getDateColor,
} from "../../utils/format";
import Icons from "../../utils/icon";
import LoadingPage from "../../utils/loading";

const EmpDetails = () => {
  const { id } = useParams(); // Get Emp ID from URL params
  const [empDetails, setEmpDetails] = useState({});
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [previewFile, setPreviewFile] = useState(null);
  const [open, setOpen] = useState(false);
  const triggerToast = useToast();

  useEffect(() => {
    const fetchEmp = async () => {
      try {
        setLoading(true); // Set loading to true before starting the fetch
        const requestData = {
          id,
          username: localStorage.getItem("username"),
          session_id: localStorage.getItem("session_id"),
        };

        const response = await axios.post(
          `${config.apiBaseUrl}/employee/get_by_id_emp.php`,
          requestData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.success) {
          setEmpDetails(response.data.employee);
        } else {
          triggerToast("error", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching employee:", error);
        triggerToast("error", "Failed to load employee details.");
      } finally {
        setLoading(false); // Set loading to false after fetch is complete
      }
    };

    fetchEmp();
  }, [id]);

  const handleBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  const handleEdit = () => {
    navigate(`/edit_emp/${id}`);
  };

  const handlePrint = () => {
    const paperContainer = document.getElementById("print-area"); // Assuming your print area has the ID 'emp-report-container'
    if (paperContainer) {
      const printWindow = window.open("", "_blank");
      printWindow.document.write(
        "<html><head><title>Print</title><style>@page { size: A4; }</style></head><body>"
      );
      // Write only the inner content of the container to the print window
      printWindow.document.write(paperContainer.innerHTML);
      printWindow.document.write("</body></html>");
      printWindow.document.close();
      printWindow.focus();
      printWindow.print(); // Trigger the print dialog
      printWindow.close(); // Close the window after printing
    }
  };

  const handleDelete = async () => {
    try {
      const deleteData = {
        id: id,
        username: localStorage.username,
        session_id: localStorage.session_id,
      };

      console.log("Delete Request Data:", deleteData);

      const response = await axios.post(
        `${config.apiBaseUrl}/employee/delete_emp.php`,
        deleteData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Delete Response:", response);

      if (response.data.success) {
        triggerToast("success", response.data.message);
        navigate("/emp");
      } else {
        triggerToast("error", response.data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      triggerToast("error", "Something went wrong!");
    }
  };

  const getDeviceName = (userAgent) => {
    if (/Android/i.test(userAgent)) {
      return "Android Device";
    } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
      return "iOS Device";
    } else if (/Windows NT/i.test(userAgent)) {
      return "Windows PC";
    } else if (/Macintosh/i.test(userAgent)) {
      return "Mac";
    } else if (/Linux/i.test(userAgent)) {
      return "Linux Device";
    } else {
      return "Unknown Device";
    }
  };

  const userAgent = navigator.userAgent; // Fetch the User-Agent string
  const deviceInfo = getDeviceName(userAgent);

  const handleGeneratePDF = () => {
    const element = document.getElementById("emp-report-container");

    // Wrap the content and footer in a container
    const pdfContainer = document.createElement("div");
    pdfContainer.style.position = "relative";
    pdfContainer.style.width = "100%";

    // Clone the content to avoid modifying the original
    const contentClone = element.cloneNode(true);

    // Create the footer
    const footer = document.createElement("div");
    footer.style.position = "relative";
    footer.style.bottom = "0";
    footer.style.width = "100%";
    footer.style.textAlign = "center";
    footer.style.fontSize = "12px";
    footer.style.padding = "10px 0";
    footer.style.borderTop = "1px solid #ccc";
    footer.innerHTML = `
      Created on: ${new Date().toLocaleDateString()} | System Info: ${
        localStorage.deviceInfo || "Unknown Device"
      } | Created by: ${localStorage.username} with session ${
        localStorage.session_id
      }
    `;

    // Append the content and footer to the container
    pdfContainer.appendChild(contentClone);
    pdfContainer.appendChild(footer);

    document.body.appendChild(pdfContainer); // Temporarily add it to the DOM for rendering

    html2pdf()
      .from(pdfContainer)
      .set({
        margin: [10, 10, 10, 10], // Margins for content
        html2canvas: { scale: 2 }, // High-quality rendering
        pagebreak: { mode: "avoid-all" }, // Avoid breaking content
      })
      .toPdf()
      .get("pdf")
      .then(function (pdf) {
        const fileName = `${id}_${empDetails.emp_name}.pdf`;
        pdf.save(fileName);
      })
      .finally(() => {
        // Remove the container after generating the PDF
        document.body.removeChild(pdfContainer);
      });
  };

  const FloatingActionButtons = () => {
    return (
      <SpeedDial
        ariaLabel="Action Buttons"
        sx={{
          position: "absolute", // Use absolute positioning to place it over the paper
          bottom: 16, // Space from the bottom of the container
          right: 16, // Space from the right of the container
          zIndex: 1000,
          // flexDirection: "row",
          justifyContent: "end",
          // Ensure it stays above other elements
        }}
        icon={<Icons.Add />}
      >
        <SpeedDialAction
          icon={<Icons.Edit />}
          tooltipTitle="Edit"
          onClick={handleEdit}
        />
        <SpeedDialAction
          icon={<Icons.PrintIcon />}
          tooltipTitle="Print"
          onClick={handlePrint}
        />
        <SpeedDialAction
          icon={<Icons.DeleteIcon />}
          tooltipTitle="Delete"
          onClick={handleDelete}
        />
        <SpeedDialAction
          icon={<Icons.PictureAsPdfIcon />}
          tooltipTitle="Generate PDF"
          onClick={handleGeneratePDF}
        />
      </SpeedDial>
    );
  };

  const handlePreviewOpen = (file) => {
    setPreviewFile(file);
    setOpen(true);
  };

  const handlePreviewClose = () => {
    setOpen(false);
    setPreviewFile(null);
  };

  function FilePreviewButtons({ file, label, onClick }) {
    const [fileExists, setFileExists] = useState(true);

    useEffect(() => {
      const checkFileExists = async () => {
        if (file) {
          console.log(`Checking file: ${file}`);
          try {
            const response = await fetch(file, { method: "HEAD" });

            // Validate Content-Type for expected file types
            const contentType = response.headers.get("Content-Type");
            const validContentTypes = [
              "application/pdf",
              "image/jpeg",
              "image/png",
            ];
            const isValidFile = validContentTypes.includes(contentType);

            if (response.ok && isValidFile) {
              console.log(
                `File check for ${file} succeeded (Status: ${response.status})`
              );
              setFileExists(true);
            } else {
              console.log(
                `File check for ${file} failed: Invalid file or content type`
              );
              setFileExists(false);
            }
          } catch (error) {
            console.error(`File check for ${file} failed with error:`, error);
            setFileExists(false);
          }
        }
      };

      checkFileExists();
    }, [file]);

    return (
      <Button
        variant="outlined"
        onClick={onClick}
        disabled={!fileExists} // Disable the button if the file doesn't exist
        startIcon={<Icons.Visibility />} // Adds the preview icon at the start
      >
        {label}
      </Button>
    );
  }

  const renderKeyValueItems = (items) => {
    return (
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap", // Ensures items wrap to the next row
          gap: 2, // Space between items
          justifyContent: "space-between", // Distributes items evenly
        }}
      >
        {items.map(({ label, value, width }, index) => (
          <Box
            key={index}
            sx={{ flex: `1 1 ${width}` }} // Apply custom width from the array
          >
            <Typography
              variant="body1"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Typography variant="body1" sx={{ fontWeight: "bold", mr: 0.5 }}>
                {label}:
              </Typography>
              <Typography variant="body1">{value}</Typography>
            </Typography>
          </Box>
        ))}
      </Box>
    );
  };

  // ---------------------------------disply cells--------------------------------

  const personalDetails = [
    { label: "Employee ID", value: empDetails.id, width: "30%" },
    {
      label: "Full Name",
      value: `${empDetails.given_name} ${empDetails.surname}`,
      width: "65%",
    },
    { label: "Nationality", value: empDetails.nationality, width: "30%" },
    { label: "Gender", value: empDetails.sex, width: "30%" },
    { label: "Date of Birth", value: formatDate(empDetails.dob), width: "30%" },
    {
      label: "Phone Number",
      value: formatPhoneNumber(empDetails.phone_number),
      width: "30%",
    },
    {
      label: "Wapp Number",
      value: formatPhoneNumber(empDetails.wapp_number),
      width: "30%",
    },
    {
      label: "Em Contact",
      value: formatPhoneNumber(empDetails.emergency_number),
      width: "30%",
    },
  ];

  const passportDetails = [
    {
      label: "Passport Number",
      value: empDetails.passport_number,
      width: "30%",
    },
    {
      label: "Place of Birth",
      value: empDetails.place_of_birth,
      width: "65%",
    },
    { label: "Place of issue", value: empDetails.place_of_issue, width: "30%" },

    {
      label: "Issue Date",
      value: formatDate(empDetails.passport_issue_date),
      width: "30%",
    },

    {
      label: "Expiry Date",
      value: formatDate(empDetails.passport_expiry_date),
      width: "30%",
    },
  ];

  const idDetails = [
    {
      label: "ID Number",
      value: empDetails.id_number,
      width: "30%",
    },
    {
      label: "ID issue Date",
      value: formatDate(empDetails.id_issue_date),
      width: "30%",
    },
    {
      label: "ID Expiry",
      value: formatDate(empDetails.id_expiry_date),
      width: "30%",
    },
  ];

  const companyDetails = [
    {
      label: "Company Name",
      value: empDetails.company_name,
      width: "30%",
    },
    {
      label: "Position",
      value: empDetails.position,
      width: "30%",
    },
  ];

  const bankDetails = [
    {
      label: "Bank name",
      value: empDetails.bank_name,
      width: "30%",
    },
    {
      label: "Account number",
      value: formatCardNumber(empDetails.acc_number),
      width: "30%",
    },
    {
      label: "Card number",
      value: formatCardNumber(empDetails.card_number),
      width: "30%",
    },
    {
      label: "Expiry",
      value: formatDate(empDetails.card_expiry),
      width: "30%",
    },
    {
      label: "Pin",
      value: empDetails.pin,
      width: "30%",
    },
    {
      label: "CVV",
      value: empDetails.cvv,
      width: "30%",
    },
    {
      label: "Branch",
      value: empDetails.branch,
      width: "30%",
    },
    {
      label: "Remarks",
      value: empDetails.remarks,
      width: "30%",
    },
  ];

  return (
    <Layout>
      <Box
        id="print-area"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "start",
          maxHeight: "85vh",
          minHeight: "600px", // Fixed height for outer Box
          overflow: "hidden", // Prevent scrolling for outer Box
        }}
      >
        {/* A4 Sized Container */}
        <Paper
          id="emp-report-container"
          sx={{
            padding: 3,
            width: "100%",
            maxWidth: 800,
            border: "0px solid black",
            boxSizing: "border-box",
            overflowY: "auto", // Enables vertical scrolling
            overflowX: "auto", // Enables horizontal scrolling if needed
          }}
        >
          <Box
            sx={{
              position: "relative",
            }}
          >
            {/* Floating Back Button */}
            <Fab
              color="primary"
              size="small"
              onClick={handleBack}
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: 1000,
              }}
            >
              <Icons.ArrowBack />
            </Fab>
          </Box>
          {loading ? (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
              <LoadingPage />
            </Box>
          ) : (
            <Box>
              <Typography variant="h5" align="center" sx={{ mb: 2 }}>
                Employee Details
              </Typography>
              <Box sx={{ borderBottom: "1px solid #808080", mb: 2 }} />
              <Box>{renderKeyValueItems(personalDetails)}</Box>
              <Box
                sx={{ borderBottom: "1px solid #808080", mb: 2, paddingTop: 1 }}
              />
              <Box>{renderKeyValueItems(passportDetails)}</Box>
              <Box
                sx={{ borderBottom: "1px solid #808080", mb: 2, paddingTop: 1 }}
              />
              <Box>{renderKeyValueItems(idDetails)}</Box>
              <Box
                sx={{ borderBottom: "1px solid #808080", mb: 2, paddingTop: 1 }}
              />
              <Box>{renderKeyValueItems(companyDetails)}</Box>
              <Box
                sx={{
                  paddingTop: 2,
                  position: "relative",
                  borderBottom: "1px solid #808080",
                  my: 2,
                  textAlign: "center",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    position: "absolute",
                    paddingTop: 2,
                    top: "-10px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    backgroundColor: "white",
                    px: 1,
                    fontWeight: "bold",
                  }}
                >
                  Bank Details
                </Typography>
              </Box>{" "}
              <Box>{renderKeyValueItems(bankDetails)}</Box>
              <Box
                sx={{
                  position: "relative",
                  borderBottom: "1px solid #808080",
                  my: 2,
                  textAlign: "center",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    position: "absolute",
                    top: "-10px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    backgroundColor: "white",
                    px: 1,
                    fontWeight: "bold",
                  }}
                >
                  Files
                </Typography>
              </Box>{" "}
              <Grid container spacing={2} sx={{ mt: 2 }}>
                {/* Left Side: File Preview Buttons */}
                <Grid item xs={6}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 1, // Space between buttons
                    }}
                  >
                    {empDetails.pass_front && (
                      <FilePreviewButtons
                        file={empDetails.pass_front}
                        label="Passport Front"
                        onClick={() => handlePreviewOpen(empDetails.pass_front)}
                      />
                    )}
                    {empDetails.pass_back && (
                      <FilePreviewButtons
                        file={empDetails.pass_back}
                        label="Passport Back"
                        onClick={() => handlePreviewOpen(empDetails.pass_back)}
                      />
                    )}
                    {empDetails.id_front && (
                      <FilePreviewButtons
                        file={empDetails.id_front}
                        label="Id back"
                        onClick={() => handlePreviewOpen(empDetails.id_front)}
                      />
                    )}
                    {empDetails.id_back && (
                      <FilePreviewButtons
                        file={empDetails.id_front}
                        label="Id back"
                        onClick={() => handlePreviewOpen(empDetails.id_back)}
                      />
                    )}
                    {empDetails.photo && (
                      <FilePreviewButtons
                        file={empDetails.photo}
                        label="Id back"
                        onClick={() => handlePreviewOpen(empDetails.photo)}
                      />
                    )}
                    {empDetails.doc1 && (
                      <FilePreviewButtons
                        file={empDetails.doc1}
                        label="Id back"
                        onClick={() => handlePreviewOpen(empDetails.doc1)}
                      />
                    )}
                    {empDetails.doc2 && (
                      <FilePreviewButtons
                        file={empDetails.doc2}
                        label="Id back"
                        onClick={() => handlePreviewOpen(empDetails.doc2)}
                      />
                    )}
                  </Box>
                </Grid>

                {/* Right Side: Change Files Button */}
                <Grid item xs={6}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end", // Aligns the button to the right
                      alignItems: "center", // Vertically centers the button
                      height: "100%", // Makes the box take full height
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => navigate(`/emp_files/${empDetails.id}`)} // Navigate to emp_files with emp ID
                      startIcon={<Icons.UploadFileIcon />}
                    >
                      Change Files
                    </Button>
                  </Box>
                </Grid>
              </Grid>
              {/* Preview Modal */}
              <Modal
                open={open}
                onClose={handlePreviewClose}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    bgcolor: "background.paper",
                    padding: 4,
                    width: "80%",
                    height: "80%",
                    maxWidth: "800px",
                    maxHeight: "600px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <iframe
                    src={previewFile}
                    style={{
                      width: "100%",
                      height: "100%",
                      border: "none",
                      objectFit: "contain",
                    }}
                    allowFullScreen
                  ></iframe>
                </Box>
              </Modal>
            </Box>
          )}
        </Paper>
        <FloatingActionButtons />
      </Box>
    </Layout>
  );
};

export default EmpDetails;
