import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  TableSortLabel,
  Fab,
  Checkbox,
  MenuItem,
  IconButton,
  InputAdornment,
} from "@mui/material";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useNavigate } from "react-router-dom";
import Icons from "../../utils/icon";
import config from "../../config";
import Layout from "../../utils/Layout";
import { formatDate } from "../../utils/format";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

import {
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
} from "@mui/icons-material";

const months = [
  { value: "1", label: "January" },
  { value: "2", label: "February" },
  { value: "3", label: "March" },
  { value: "4", label: "April" },
  { value: "5", label: "May" },
  { value: "6", label: "June" },
  { value: "7", label: "July" },
  { value: "8", label: "August" },
  { value: "9", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
];

const TransactionTable = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [showSearchBar, setShowSearchBar] = useState(false);

  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [data, setData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [siteNames, setSiteNames] = useState({});

  const navigate = useNavigate();
  const currentDate = new Date();
  const currentMonth = (currentDate.getMonth() + 1).toString(); // Add 1 since months are 0-indexed
  const currentYear = currentDate.getFullYear().toString();

  const [month, setMonth] = useState(currentMonth);
  const [year, setYear] = useState(currentYear);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const username = localStorage.getItem("username");
      const session_id = localStorage.getItem("session_id");

      if (username && session_id) {
        try {
          setLoading(true);
          const response = await fetch(
            `${config.apiBaseUrl}/transaction/get_all_transaction.php`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                username,
                session_id,
                search: searchQuery || null,
                month,
                year,
              }),
            }
          );

          const result = await response.json();
          if (result.success) {
            console.log("Fetched transaction data:", result.data); // Log fetched transaction data

            setData(result.data);
          } else {
            console.error("Error fetching transaction:", result.message);
          }
        } catch (error) {
          console.error("Error fetching transaction:", error);
        } finally {
          setLoading(false);
        }
      } else {
        navigate("/");
      }
    };

    fetchData();
  }, [searchQuery, month, year]);

  useEffect(() => {
    if (data.length > 0) {
      const siteNames = data.map((transaction) => transaction.site_name);
      fetchSiteNames(siteNames);
    }
  }, [data]);

  const fetchSiteNames = async (siteNames) => {
    const uniqueSiteNames = [...new Set(siteNames)];
    const names = {};
    const username = localStorage.getItem("username");
    const session_id = localStorage.getItem("session_id");

    if (!username || !session_id) {
      console.error("Username or session ID is missing.");
      return;
    }

    console.log("Sending request for siteNames:", uniqueSiteNames);

    // Check if site names are already stored in sessionStorage
    const cachedSiteNames = JSON.parse(
      sessionStorage.getItem("siteNamesCache") || "{}"
    );

    for (const siteName of uniqueSiteNames) {
      if (!siteName) {
        console.error(`Invalid siteName: ${siteName}`);
        continue;
      }

      if (cachedSiteNames[siteName]) {
        // Use cached site name if available
        names[siteName] = cachedSiteNames[siteName];
        console.log(`Using cached site name for ${siteName}:`, names[siteName]);
        continue;
      }

      const requestBody = {
        username,
        session_id,
        siteId: siteName, // Use site_name here as site_id
      };

      console.log("Sending request for siteName:", siteName);
      console.log("Request Body:", requestBody);

      try {
        const response = await fetch(
          `${config.apiBaseUrl}/site/get_site_name.php`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          }
        );

        const result = await response.json();
        console.log(`Response for siteName ${siteName}:`, result);

        if (result.success) {
          // Ensure site name exists before assigning
          const siteNameFetched = result.data?.site_name || "Unknown Site Name";
          names[siteName] = siteNameFetched;

          // Store the fetched site name in the cache (sessionStorage)
          cachedSiteNames[siteName] = siteNameFetched;
        } else {
          console.error(`Failed to fetch site name for siteName: ${siteName}`);
        }
      } catch (error) {
        console.error(
          `Error fetching site name for siteName: ${siteName}`,
          error
        );
      }
    }

    console.log("Fetched site names:", names);
    setSiteNames(names);

    // Update the cache in sessionStorage
    sessionStorage.setItem("siteNamesCache", JSON.stringify(cachedSiteNames));
  };

  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction });
  };

  const handleCheckboxChange = (id) => {
    setSelectedData((prev) =>
      prev.includes(id) ? prev.filter((dataId) => dataId !== id) : [...prev, id]
    );
  };

  const handleClear = () => {
    setSearchQuery("");
  };

  // Move to the previous month
  const handlePrevMonth = () => {
    let prevMonth = parseInt(month) - 1;
    let prevYear = year;

    if (prevMonth < 1) {
      prevMonth = 12;
      prevYear = (parseInt(year) - 1).toString();
    }

    setMonth(prevMonth.toString());
    setYear(prevYear);
  };

  // Move to the next month
  const handleNextMonth = () => {
    let nextMonth = parseInt(month) + 1;
    let nextYear = year;

    if (nextMonth > 12) {
      nextMonth = 1;
      nextYear = (parseInt(year) + 1).toString();
    }

    setMonth(nextMonth.toString());
    setYear(nextYear);
  };

  // Filtered data based on month, year, and search query
  const filteredData = data.filter((item) => {
    const itemDate = new Date(item.date);
    const matchesMonth = month
      ? itemDate.getMonth() + 1 === parseInt(month)
      : true;
    const matchesYear = year
      ? itemDate.getFullYear().toString() === year
      : true;
    const matchesSearch = item.site_name
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    return matchesMonth && matchesYear && matchesSearch;
  });

  const sortedData = filteredData.sort((a, b) => {
    const order = sortConfig.direction === "asc" ? 1 : -1;
    if (a[sortConfig.key] < b[sortConfig.key]) return -order;
    if (a[sortConfig.key] > b[sortConfig.key]) return order;
    return 0;
  });

  const handleSearchClick = () => {
    setShowSearchBar(!showSearchBar); // Toggle search bar visibility
  };

  const handleExportPDF = () => {
    if (selectedData.length === 0) {
      console.warn("No data selected for export.");
      return;
    }
    const doc = new jsPDF();
    const tableData = selectedData.map((id) => {
      const transaction = data.find((item) => item.id === id);
      return [
        transaction.id,
        transaction.site_name,
        transaction.amount,
        transaction.date,
        transaction.remarks,
      ];
    });

    doc.text("Transaction Report", 14, 10);
    doc.autoTable({
      head: [["Id", "Site Name", "Amount", "Date", "Remarks"]],
      body: tableData,
    });

    doc.save("Transaction_List.pdf");
  };

  const theme = createTheme({
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: "4px",
          },
        },
      },
    },
  });

  const cellStyles = {
    border: "none",
    boxShadow: "0px 0px 0px 0.5px rgba(127, 147, 184, 0.2)",
  };

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          height: "85vh",
          maxWidth: "lg",
          margin: "auto",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            width: "100%",
            position: "sticky",
            top: 0,
            zIndex: 2,
            backgroundColor: "white",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            display: "flex",
            justifyContent: "Center",
            alignItems: "center",
            paddingRight: 20,
            paddingLeft: 20,
            paddingBottom: 1,
            paddingTop: 1,
            gap: 1,
          }}
        >
          <Fab
            color="primary"
            aria-label="export"
            onClick={handleExportPDF}
            disabled={selectedData.length === 0}
            sx={{
              borderRadius: 1,
              width: 35,
              height: 35,
              minHeight: "unset",
            }}
          >
            <Icons.Download />
          </Fab>

          {/* Previous Month Button */}
          <Fab
            color="primary"
            aria-label="previous"
            onClick={handlePrevMonth}
            sx={{
              borderRadius: 1,
              width: 35,
              height: 35,
              minHeight: "unset",
            }}
          >
            <ArrowBackIcon />
          </Fab>

          <TextField
            variant="outlined"
            select
            label="Month"
            value={month}
            onChange={(e) => setMonth(e.target.value)}
            size="small"
            sx={{ flexGrow: 0.5, marginLeft: 0 }}
          >
            {months.map((month) => (
              <MenuItem key={month.value} value={month.value}>
                {month.label}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            variant="outlined"
            select
            label="Year"
            value={year}
            onChange={(e) => setYear(e.target.value)}
            size="small"
            sx={{ flexGrow: 0.5, marginLeft: 1 }}
          >
            {[...Array(3)].map((_, index) => {
              const yearOption = (parseInt(currentYear) - index).toString();
              return (
                <MenuItem key={yearOption} value={yearOption}>
                  {yearOption}
                </MenuItem>
              );
            })}
          </TextField>

          <Fab
            color="primary"
            aria-label="next"
            onClick={handleNextMonth}
            sx={{
              borderRadius: 1,
              width: 35,
              height: 35,
              minHeight: "unset",
            }}
          >
            <ArrowForwardIcon />
          </Fab>

          <Box display="flex" alignItems="center">
            {isSmallScreen ? (
              <IconButton onClick={handleSearchClick}>
                <Icons.SearchIcon />
              </IconButton>
            ) : (
              <TextField
                variant="outlined"
                placeholder="Search Transaction..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                size="small"
                sx={{ flexGrow: 0.5, marginLeft: 0 }}
                InputProps={{
                  endAdornment: searchQuery && (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClear} edge="end">
                        <Icons.ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}

            {/* Display search bar only when clicked on small screens */}
            {isSmallScreen && showSearchBar && (
              <TextField
                variant="outlined"
                placeholder="Search Transaction..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                size="small"
                sx={{
                  flexGrow: 0.5,
                  marginLeft: 1,
                  transition: "all 0.3s ease", // Add transition for smooth appearance
                }}
              />
            )}
          </Box>
          <Fab
            color="primary"
            aria-label="add"
            onClick={() => navigate("/add_transaction")}
            sx={{
              borderRadius: 1,
              width: 35,
              height: 35,
              minHeight: "unset",
            }}
          >
            <Icons.Add />
          </Fab>
        </Box>

        {/* Table Container */}
        <ThemeProvider theme={theme}>
          <TableContainer
            sx={{
              flexGrow: 1,
              height: "calc(100vh - 90px)",
              overflow: "auto",
              "&::-webkit-scrollbar": {
                width: "5px",
                height: "5px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                borderRadius: "10px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.7)",
              },
            }}
          >
            <Table
              stickyHeader
              sx={{
                tableLayout: "auto",
                width: "lg",
              }}
            >
              {" "}
              <TableHead>
                <TableRow
                  sx={{
                    bgcolor: "primary.main",
                    color: "white",
                    zIndex: 1000,
                  }}
                >
                  {" "}
                  <TableCell padding="checkbox">
                    <Checkbox
                      indeterminate={
                        selectedData.length > 0 &&
                        selectedData.length < sortedData.length
                      }
                      checked={
                        selectedData.length === sortedData.length &&
                        sortedData.length > 0
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedData(sortedData.map((data) => data.id)); // Select all
                        } else {
                          setSelectedData([]); // Deselect all
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortConfig.key === "name"}
                      direction={sortConfig.direction}
                      onClick={() => handleSort("name")}
                    >
                      ID
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Site </TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Remarks</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              {loading ? (
                <div className="loading-container">
                  <div className="spinner"></div>
                  <p>Loading, please wait...</p>
                </div>
              ) : (
                <TableBody>
                  {sortedData.map((data) => (
                    <TableRow
                      key={data.id}
                      sx={{
                        "&:nth-of-type(odd)": { bgcolor: "grey.200" }, // Alternate row colors
                      }}
                    >
                      <TableCell>
                        <Checkbox
                          checked={selectedData.includes(data.id)}
                          onChange={() => handleCheckboxChange(data.id)}
                        />
                      </TableCell>
                      <TableCell sx={cellStyles}>{data.id}</TableCell>
                      <TableCell sx={cellStyles}>
                        {siteNames[data.site_name] || data.site_name}
                      </TableCell>

                      <TableCell sx={cellStyles}>{data.amount}</TableCell>
                      <TableCell sx={cellStyles}>
                        {formatDate(data.date)}
                      </TableCell>
                      <TableCell sx={cellStyles}>{data.remarks}</TableCell>
                      <TableCell sx={cellStyles}>
                        <Fab
                          color="primary"
                          aria-label="edit"
                          onClick={() =>
                            navigate(`/edit_transaction/${data.id}`)
                          }
                          sx={{
                            width: 35,
                            height: 35,
                            borderRadius: "4px",
                            minHeight: "unset",
                            minWidth: "unset",
                            zIndex: 1,
                          }}
                        >
                          <Icons.EditNoteIcon sx={{ fontSize: 20 }} />
                        </Fab>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          {/* Total Amount Row */}
          <Table>
            <TableRow
              sx={{
                backgroundColor: "#f5f5f5", // Light gray background
                "&:last-child td, &:last-child th": { border: 0 }, // Remove borders if it's the last row
              }}
            >
              <TableCell
                colSpan={3}
                sx={{
                  fontWeight: "bold",
                  textAlign: "right",
                  fontSize: "1rem",
                  padding: "16px",
                  borderBottom: "none", // Optional: Remove bottom border for cleaner look
                }}
              >
                Total
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  fontSize: "1rem",
                  color: "#1976d2", // Primary theme color for emphasis
                  textAlign: "right", // Align total amount text
                  padding: "16px",
                  borderBottom: "none",
                }}
              >
                ₹
                {filteredData
                  .reduce(
                    (total, item) => total + parseFloat(item.amount || 0),
                    0
                  )
                  .toFixed(3)}{" "}
                {/* Format the amount to 2 decimal places */}
              </TableCell>
              <TableCell colSpan={3}></TableCell>
            </TableRow>
          </Table>
        </ThemeProvider>
      </Box>
    </Layout>
  );
};

export default TransactionTable;
