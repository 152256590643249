import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  Button,
  Box,
  Typography,
  Container,
  Divider,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Layout from "../../utils/Layout";
import config from "../../config";
import { useToast } from "../../utils/ToastContext";

const EditSite = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const triggerToast = useToast();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [formData, setFormData] = useState({
    id: id,
    site_name: "",
    site_location: "",
    site_map: "",
    site_amount: "",
    start_date: "",
    end_date: "",
    client_name: "",
    client_number: "",
    client_location: "",
    Description: "",
    Remarks: "",
    username: localStorage.username, // Retrieve from localStorage
    session_id: localStorage.session_id, // Retrieve from localStorage
  });

  // Fetch existing trabs data for editing
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true); // Set loading to true before starting the fetch
        const requestData = {
          id,
          username: localStorage.getItem("username"),
          session_id: localStorage.getItem("session_id"),
        };

        console.log("Fetching Quote with ID:", id);
        const response = await axios.post(
          `${config.apiBaseUrl}/site/get_by_id_site.php`,
          requestData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          },
        );

        if (response.data.success) {
          console.log("Full response:", response);
          console.log("Response data:", response.data);

          const fetchedData = response.data.site;
          setFormData((prevData) => ({ ...prevData, ...fetchedData }));
          console.log(fetchedData);
        } else {
          triggerToast(
            "error",
            response.data.message || "Failed to fetch site data",
          );
        }
      } catch (error) {
        console.error("Error fetching site data:", error);
        triggerToast("error", "An error occurred while fetching site data.");
      } finally {
        setLoading(false); // Set loading to false after fetch is complete
      }
    };

    fetchData();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    let formattedValue = value;

    if (name === "site_name") {
      // Capitalize first letter of each word & allow numbers
      formattedValue = value
        .toLowerCase()
        .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize first letter of each word
    }

    if (name === "client_name") {
      // Capitalize first letter of each word & prevent numbers
      formattedValue = value
        .replace(/[^a-zA-Z\s]/g, "") // Remove numbers and special characters
        .toLowerCase()
        .replace(/\b\w/g, (char) => char.toUpperCase());
    }

    if (name === "site_location" || name === "client_location") {
      // Allow only letters and spaces
      formattedValue = value.replace(/[^a-zA-Z\s]/g, "");
    }

    if (name === "site_amount" || name === "client_number") {
      // Allow only numbers
      formattedValue = value.replace(/[^0-9]/g, "");
    }

    setFormData({
      ...formData,
      [name]: formattedValue,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const updatedFormData = {
        ...formData,
        id: id,
        username: localStorage.username,
        session_id: localStorage.session_id,
      };

      console.log("Data sent:", updatedFormData);
      const response = await axios.post(
        `${config.apiBaseUrl}/site/update_site.php`,
        updatedFormData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      console.log("Response data:", response.data);

      if (response.data.success) {
        triggerToast("success", response.data.message); // Show success message
        // Redirect to /site after a short delay
        navigate("/site");
      } else {
        triggerToast("error", response.data.message); // Show error message
      }
    } catch (error) {
      console.error("Error:", error);
      triggerToast("error", "Something went wrong!"); // General error
    }
  };

  // const handleBack = () => {
  //   navigate("/site");
  // };

  const handleBack = () => {
    navigate(-1); // Navigate to the previous page
  };

  const handleFile = () => {
    navigate(`/site_files/${id}`);
  };

  return (
    <Layout>
      <Container
        maxWidth="md"
        style={{
          overflow: "auto",
          maxHeight: "85vh",
          marginBottom: 10,
          scrollbarWidth: "thin",
          scrollbarColor: "lightgrey transparent",
          padding: "16px",
          border: "1px solid #e0e0e0",
          borderRadius: "8px",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#ffffff",
        }}
      >
        {!isMobile && ( // Hide on mobile
          <Box display="flex" alignItems="center" mb={3}>
            <IconButton onClick={handleBack}>
              <ArrowBack />
            </IconButton>
            <Typography variant="h5" ml={1}>
              Edit Site
            </Typography>
          </Box>
        )}
        <form onSubmit={handleSubmit}>
          {/* Personal Details Section */}
          <Box mb={4}>
            <Typography variant="h6" gutterBottom>
              Site Details
            </Typography>
            <Divider />
            <Box display="flex" flexDirection="column" gap={2} mb={2}>
              {/* Dropdown for Site Names */}
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    label="Site Name"
                    variant="outlined"
                    name="site_name"
                    value={formData.site_name}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Site Location"
                    variant="outlined"
                    name="site_location"
                    value={formData.site_location}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    label="Site Map"
                    variant="outlined"
                    name="site_map"
                    value={formData.site_map}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Total Value"
                    variant="outlined"
                    name="site_amount"
                    // type="number"
                    value={formData.site_amount}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    label="Start Date"
                    type="date"
                    variant="outlined"
                    name="start_date"
                    value={
                      formData.start_date ||
                      new Date().toISOString().split("T")[0]
                    }
                    onChange={handleChange}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="End Date"
                    type="date"
                    variant="outlined"
                    name="end_date"
                    value={formData.end_date || ""}
                    onChange={handleChange}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Grid>

              <Typography variant="h6" gutterBottom>
                Client Details
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    label="Client Name"
                    variant="outlined"
                    name="client_name"
                    value={formData.client_name}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Client Number"
                    variant="outlined"
                    name="client_number"
                    type="tel"
                    value={formData.client_number}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    label="Client Location"
                    variant="outlined"
                    name="client_location"
                    value={formData.client_location}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Remarks"
                    variant="outlined"
                    name="remarks"
                    value={formData.remarks}
                    onChange={handleChange}
                    multiline
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Grid>
              <Typography variant="h6" gutterBottom>
                Other Details
              </Typography>
              <TextField
                label="Description"
                variant="outlined"
                name="description"
                value={formData.description}
                onChange={handleChange}
                multiline
                rows={4}
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Box>
          </Box>
          {/* Buttons */}
          <Box mt={4} display="flex" justifyContent="space-between">
            <Button
              type="button"
              variant="outlined"
              color="secondary"
              onClick={handleBack}
              style={{ flex: 1, marginRight: "8px" }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ flex: 1 }}
            >
              Save{" "}
            </Button>
          </Box>
        </form>
      </Container>
    </Layout>
  );
};

export default EditSite;
