import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  Button,
  Box,
  Typography,
  Container,
  Divider,
  IconButton,
  MenuItem,
  useMediaQuery,
  useTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useToast } from "../../utils/ToastContext";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Layout from "../../utils/Layout";
import config from "../../config";

const EditTransaction = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [siteList, setSiteList] = useState([]); // To store site names
  const triggerToast = useToast();
  const theme = useTheme();
  const [openDialog, setOpenDialog] = useState(false);

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [formData, setFormData] = useState({
    id: id,
    site_name: "",
    amount: "",
    remarks: "",
    date: "",
    username: localStorage.username,
    session_id: localStorage.session_id,
  });

  // Fetch site names from the API
  useEffect(() => {
    const fetchSiteNames = async () => {
      try {
        const response = await axios.post(
          `${config.apiBaseUrl}/site/get_site_list.php`,
          {
            username: localStorage.username,
            session_id: localStorage.session_id,
          },
          {
            headers: {
              "Content-Type": " application/json",
            },
          }
        );

        if (response.data.success) {
          setSiteList(response.data.data); // Assuming the data is an array of { id, surname }
        } else {
          triggerToast("error", response.data.message); // Show error message
        }
      } catch (error) {
        console.error("Error fetching site names:", error);
        triggerToast("error", "Failed to load site names.");
      }
    };

    fetchSiteNames();
  }, []);

  // Fetch existing transaction data for editing
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const requestData = {
          id,
          username: localStorage.getItem("username"),
          session_id: localStorage.getItem("session_id"),
        };

        const response = await axios.post(
          `${config.apiBaseUrl}/transaction/get_by_id_transaction.php`,
          requestData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.success) {
          const fetchedData = response.data.transaction;
          setFormData((prevData) => ({ ...prevData, ...fetchedData }));
        } else {
          triggerToast(
            "error",
            response.data.message || "Failed to fetch transaction data."
          );
        }
      } catch (error) {
        console.error("Error fetching transaction data:", error);
        triggerToast(
          "error",
          "An error occurred while fetching transaction data."
        );
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "amount") {
      // Allow only numbers with up to 3 decimal places
      if (!/^\d*\.?\d{0,3}$/.test(value)) return;
    }

    if (name === "date") {
      // Ensure the year is limited to four digits
      if (!/^\d{4}-\d{2}-\d{2}$/.test(value)) return;
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const updatedFormData = {
        ...formData,
        id: id,
        username: localStorage.username,
        session_id: localStorage.session_id,
      };

      const response = await axios.post(
        `${config.apiBaseUrl}/transaction/update_transaction.php`,
        updatedFormData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.success) {
        triggerToast("success", response.data.message);
        navigate("/transaction");
      } else {
        triggerToast("error", response.data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      triggerToast("error", "Something went wrong!");
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleDelete = async () => {
    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/transaction/delete_transaction.php`,
        {
          id,
          username: localStorage.username,
          session_id: localStorage.session_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.success) {
        triggerToast("success", "Allowance deleted successfully!");
        navigate("/transaction");
      } else {
        triggerToast("error", response.data.message);
      }
    } catch (error) {
      console.error("Error deleting Allowance:", error);
      triggerToast("error", "Failed to delete Allowance.");
    }
  };

  return (
    <Layout>
      <Container maxWidth="md">
        {!isMobile && ( // Hide on mobile
          <Box display="flex" alignItems="center" mb={3}>
            <IconButton onClick={handleBack}>
              <ArrowBack />
            </IconButton>
            <Typography variant="h5" ml={1}>
              Edit Transaction
            </Typography>
          </Box>
        )}
        {/* Form Container */}
        <Container
          maxWidth="md"
          style={{
            overflow: "auto",
            maxHeight: "80vh",
            scrollbarWidth: "thin",
            scrollbarColor: "lightgrey transparent",
            padding: "16px",
            border: "1px solid #e0e0e0",
            borderRadius: "8px",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            backgroundColor: "#ffffff",
          }}
        >
          <form onSubmit={handleSubmit}>
            {/* Transaction Details */}
            <Box mb={4}>
              <Typography variant="h6" gutterBottom>
                Transaction Details
              </Typography>
              <Divider />
              <Box display="flex" flexDirection="column" mt={2} gap={2}>
                {/* First Row: Date & Amount */}
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Date"
                      type="date"
                      variant="outlined"
                      name="date"
                      value={formData.date}
                      onChange={handleChange}
                      required
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        pattern: "\\d{4}-\\d{2}-\\d{2}",
                        title: "Enter a valid date in YYYY-MM-DD format",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Amount"
                      variant="outlined"
                      name="amount"
                      type="text"
                      value={formData.amount}
                      onChange={handleChange}
                      required
                      fullWidth
                      inputProps={{
                        inputMode: "decimal",
    pattern: "^[0-9]*\\.?[0-9]{0,3}$",
    title: "Enter a valid amount (e.g., 123, 123.456)",
                      }}
                    />
                  </Grid>
                </Grid>

                {/* Second Row: Site Name & Remarks */}
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      select
                      label="Site Name"
                      variant="outlined"
                      name="site_name"
                      value={formData.site_name}
                      onChange={handleChange}
                      required
                      fullWidth
                    >
                      {siteList.map((site) => (
                        <MenuItem key={site.id} value={site.id}>
                          {site.site_name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Remarks"
                      variant="outlined"
                      name="remarks"
                      value={formData.remarks}
                      onChange={handleChange}
                      multiline
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>

            {/* Buttons */}
            <Box mt={2} display="flex" flexDirection="column" gap={2}>
              <Button
                type="button"
                variant="contained"
                color="secondary"
                onClick={() => setOpenDialog(true)}
                fullWidth
              >
                Delete
              </Button>

              <Button
                type="button"
                variant="outlined"
                color="secondary"
                onClick={handleBack}
                fullWidth
              >
                Cancel
              </Button>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                Save
              </Button>
            </Box>
          </form>
        </Container>

        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this Allowance?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleDelete} color="error">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Layout>
  );
};

export default EditTransaction;
