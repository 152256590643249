import React, { useState, useEffect } from "react";
import { Button, Box, Typography, Paper, Grid, Modal } from "@mui/material";
import { SpeedDial, SpeedDialAction, Fab } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import html2pdf from "html2pdf.js";
import { useToast } from "../../utils/ToastContext";
import Layout from "../../utils/Layout";
import config from "../../config";
import { formatDate } from "../../utils/format";
import Icons from "../../utils/icon";
import LoadingPage from "../../utils/loading";

const QuoteDetails = () => {
  const { id } = useParams(); // Get quote ID from URL params
  const [quoteDetails, setQuoteDetails] = useState({});
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [previewFile, setPreviewFile] = useState(null);
  const [open, setOpen] = useState(false);
  const triggerToast = useToast();

  useEffect(() => {
    const fetchQuote = async () => {
      try {
        setLoading(true); // Set loading to true before starting the fetch
        const requestData = {
          id,
          username: localStorage.getItem("username"),
          session_id: localStorage.getItem("session_id"),
        };

        const response = await axios.post(
          `${config.apiBaseUrl}/quote/get_by_id.php`,
          requestData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.success) {
          setQuoteDetails(response.data.data);
        } else {
          triggerToast("error", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching quote:", error);
        triggerToast("error", "Failed to load quote details.");
      } finally {
        setLoading(false); // Set loading to false after fetch is complete
      }
    };

    fetchQuote();
  }, [id]);

  const handleBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  const handleEdit = () => {
    navigate(`/edit_quote/${id}`);
  };

  const handlePrint = () => {
    const paperContainer = document.getElementById("print-area"); // Assuming your print area has the ID 'quote-report-container'
    if (paperContainer) {
      const printWindow = window.open("", "_blank");
      printWindow.document.write(
        "<html><head><title>Print</title><style>@page { size: A4; }</style></head><body>"
      );
      // Write only the inner content of the container to the print window
      printWindow.document.write(paperContainer.innerHTML);
      printWindow.document.write("</body></html>");
      printWindow.document.close();
      printWindow.focus();
      printWindow.print(); // Trigger the print dialog
      printWindow.close(); // Close the window after printing
    }
  };

  const handleDelete = async () => {
    try {
      const deleteData = {
        id: id,
        username: localStorage.username,
        session_id: localStorage.session_id,
      };

      console.log("Delete Request Data:", deleteData);

      const response = await axios.post(
        `${config.apiBaseUrl}/quote/delete_quote.php`,
        deleteData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Delete Response:", response);

      if (response.data.success) {
        triggerToast("warning", response.data.message);
        navigate("/quotes");
      } else {
        triggerToast("error", response.data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      triggerToast("error", "Something went wrong!");
    }
  };

  const getDeviceName = (userAgent) => {
    if (/Android/i.test(userAgent)) {
      return "Android Device";
    } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
      return "iOS Device";
    } else if (/Windows NT/i.test(userAgent)) {
      return "Windows PC";
    } else if (/Macintosh/i.test(userAgent)) {
      return "Mac";
    } else if (/Linux/i.test(userAgent)) {
      return "Linux Device";
    } else {
      return "Unknown Device";
    }
  };

  const userAgent = navigator.userAgent; // Fetch the User-Agent string
  const deviceInfo = getDeviceName(userAgent);

  const handleGeneratePDF = () => {
    const element = document.getElementById("quote-report-container");

    // Wrap the content and footer in a container
    const pdfContainer = document.createElement("div");
    pdfContainer.style.position = "relative";
    pdfContainer.style.width = "100%";

    // Clone the content to avoid modifying the original
    const contentClone = element.cloneNode(true);

    // Create the footer
    const footer = document.createElement("div");
    footer.style.position = "relative";
    footer.style.bottom = "0";
    footer.style.width = "100%";
    footer.style.textAlign = "center";
    footer.style.fontSize = "12px";
    footer.style.padding = "10px 0";
    footer.style.borderTop = "1px solid #ccc";
    footer.innerHTML = `
      Created on: ${new Date().toLocaleDateString()} | System Info: ${
        localStorage.deviceInfo || "Unknown Device"
      } | Created by: ${localStorage.username} with session ${
        localStorage.session_id
      }
    `;

    // Append the content and footer to the container
    pdfContainer.appendChild(contentClone);
    pdfContainer.appendChild(footer);

    document.body.appendChild(pdfContainer); // Temporarily add it to the DOM for rendering

    html2pdf()
      .from(pdfContainer)
      .set({
        margin: [10, 10, 10, 10], // Margins for content
        html2canvas: { scale: 2 }, // High-quality rendering
        pagebreak: { mode: "avoid-all" }, // Avoid breaking content
      })
      .toPdf()
      .get("pdf")
      .then(function (pdf) {
        const fileName = `${id}_${quoteDetails.quote_name}.pdf`;
        pdf.save(fileName);
      })
      .finally(() => {
        // Remove the container after generating the PDF
        document.body.removeChild(pdfContainer);
      });
  };

  const FloatingActionButtons = () => {
    return (
      <SpeedDial
        ariaLabel="Action Buttons"
        sx={{
          position: "absolute", // Use absolute positioning to place it over the paper
          bottom: 16, // Space from the bottom of the container
          right: 16, // Space from the right of the container
          zIndex: 1000,
          // flexDirection: "row",
          justifyContent: "end",
          // Ensure it stays above other elements
        }}
        icon={<Icons.Add />}
      >
        <SpeedDialAction
          icon={<Icons.Edit />}
          tooltipTitle="Edit"
          onClick={handleEdit}
        />
        <SpeedDialAction
          icon={<Icons.PrintIcon />}
          tooltipTitle="Print"
          onClick={handlePrint}
        />
        <SpeedDialAction
          icon={<Icons.DeleteIcon />}
          tooltipTitle="Delete"
          onClick={handleDelete}
        />
        <SpeedDialAction
          icon={<Icons.PictureAsPdfIcon />}
          tooltipTitle="Generate PDF"
          onClick={handleGeneratePDF}
        />
      </SpeedDial>
    );
  };

  const calculateDueDate = (completionDate) => {
    const dueDate = new Date(completionDate);
    dueDate.setDate(dueDate.getDate() + 90); // Add 90 days to the completion date

    const now = new Date();
    const differenceInDays = Math.ceil((dueDate - now) / (1000 * 60 * 60 * 24));

    let colorStyle = {};

    if (differenceInDays <= 7 && differenceInDays > 0) {
      colorStyle = { color: "orange" };
    } else if (differenceInDays <= 0) {
      colorStyle = { color: "red" };
    }

    return (
      <span style={colorStyle}>
        {dueDate.toLocaleDateString("default", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        })}
      </span>
    );
  };

  const handlePreviewOpen = (file) => {
    setPreviewFile(file);
    setOpen(true);
  };

  const handlePreviewClose = () => {
    setOpen(false);
    setPreviewFile(null);
  };

  function FilePreviewButtons({ file, label, onClick }) {
    const [fileExists, setFileExists] = useState(true);

    useEffect(() => {
      const checkFileExists = async () => {
        if (file) {
          console.log(`Checking file: ${file}`);
          try {
            const response = await fetch(file, { method: "HEAD" });

            // Validate Content-Type for expected file types
            const contentType = response.headers.get("Content-Type");
            const validContentTypes = [
              "application/pdf",
              "image/jpeg",
              "image/png",
            ];
            const isValidFile = validContentTypes.includes(contentType);

            if (response.ok && isValidFile) {
              console.log(
                `File check for ${file} succeeded (Status: ${response.status})`
              );
              setFileExists(true);
            } else {
              console.log(
                `File check for ${file} failed: Invalid file or content type`
              );
              setFileExists(false);
            }
          } catch (error) {
            console.error(`File check for ${file} failed with error:`, error);
            setFileExists(false);
          }
        }
      };

      checkFileExists();
    }, [file]);

    return (
      <Button
        variant="outlined"
        onClick={onClick}
        disabled={!fileExists} // Disable the button if the file doesn't exist
        startIcon={<Icons.Visibility />} // Adds the preview icon at the start
      >
        {label}
      </Button>
    );
  }

  return (
    <Layout>
      <Box
        id="print-area"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "start",
          maxHeight: "85vh",
          minHeight: "600px", // Fixed height for outer Box
          overflow: "hidden", // Prevent scrolling for outer Box
        }}
      >
        {/* A4 Sized Container */}
        <Paper
          id="quote-report-container"
          sx={{
            padding: 3,
            width: "100%",
            maxWidth: 800,
            border: "0px solid black",
            boxSizing: "border-box",
            overflowY: "auto", // Enables vertical scrolling
            overflowX: "auto", // Enables horizontal scrolling if needed
          }}
        >
          <Box
            sx={{
              position: "relative",
            }}
          >
            {/* Floating Back Button */}
            <Fab
              color="primary"
              size="small"
              onClick={handleBack}
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: 1000,
              }}
            >
              <Icons.ArrowBack />
            </Fab>
          </Box>
          {loading ? (
            <div className="loading-container">
              <div className="spinner"></div>
              <p>Loading, please wait...</p>
            </div>
          ) : (
            // <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
            //   <LoadingPage />
            // </Box>
            <Box>
              <Typography variant="h5" align="center" sx={{ mb: 2 }}>
                Quote Report: {quoteDetails.quote_name}
              </Typography>
              <Box sx={{ borderBottom: "1px solid #808080", mb: 2 }} />
              <Typography
                variant="body1"
                sx={{ display: "flex", justifyContent: "center", mb: 1 }}
              >
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={6}>
                    <Typography
                      variant="body1"
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: "bold", mr: 0.5 }}
                      >
                        Referance No:
                      </Typography>
                      <Typography variant="body1">
                        {quoteDetails.ref_no}
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="body1"
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: "bold", mr: 0.5 }}
                      >
                        Dated:
                      </Typography>
                      <Typography variant="body1">
                        {formatDate(quoteDetails.quote_date)}
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
              </Typography>
              <Box
                sx={{ borderBottom: "1px solid #808080", mb: 2, paddingTop: 1 }}
              />
              <Typography
                variant="body1"
                sx={{ display: "flex", justifyContent: "center", mb: 1 }}
              >
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={6}>
                    <Typography variant="body1" sx={{ display: "flex" }}>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: "bold", mr: 0.5 }}
                      >
                        Submitted To:
                      </Typography>
                      <Typography variant="body1">
                        {quoteDetails.submitted_to}
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" sx={{ display: "flex" }}>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: "bold", mr: 0.5 }}
                      >
                        Status:
                      </Typography>
                      <Typography variant="body1">
                        {quoteDetails.status}
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
              </Typography>
              <Typography variant="body1" sx={{ mb: 1 }}>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={6}>
                    <Typography variant="body1" sx={{ display: "flex" }}>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: "bold", mr: 0.5 }}
                      >
                        Quoted Value:
                      </Typography>
                      <Typography variant="body1">
                        {quoteDetails.quoted_amount} {config.currency}
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" sx={{ display: "flex" }}>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: "bold", mr: 0.5 }}
                      >
                        Approved Value:
                      </Typography>
                      <Typography variant="body1">
                        {quoteDetails.approved_amount} {config.currency}
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
              </Typography>
              <Typography variant="body1" sx={{ mb: 1 }}>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={6}>
                    <Typography variant="body1" sx={{ display: "flex" }}>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: "bold", mr: 0.5 }}
                      >
                        {" "}
                        Job Status :
                      </Typography>
                      <Typography variant="body1"></Typography>
                      {quoteDetails.work_status}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" sx={{ display: "flex" }}>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: "bold", mr: 0.5 }}
                      >
                        Job completion date:
                      </Typography>
                      <Typography variant="body1">
                        {formatDate(quoteDetails.date_of_completion)}
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
              </Typography>
              <Box sx={{ borderBottom: "1px solid #808080", my: 2 }} />
              <Typography variant="body1" sx={{ mb: 1 }}>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={6}>
                    <Typography variant="body1" sx={{ display: "flex" }}>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: "bold", mr: 0.5 }}
                      >
                        LPO Number:
                      </Typography>
                      <Typography variant="body1">
                        {quoteDetails.lpo_number}
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" sx={{ display: "flex" }}>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: "bold", mr: 0.5 }}
                      >
                        Date:
                      </Typography>
                      <Typography variant="body1">
                        {formatDate(quoteDetails.lpo_date)}
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
              </Typography>
              <Typography variant="body1" sx={{ mb: 1 }}>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={6}>
                    <Typography variant="body1" sx={{ display: "flex" }}>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: "bold", mr: 0.5 }}
                      >
                        Invoice Number:
                      </Typography>
                      <Typography variant="body1">
                        {quoteDetails.invoice_number}
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" sx={{ display: "flex" }}>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: "bold", mr: 0.5 }}
                      >
                        Date:
                      </Typography>
                      <Typography variant="body1">
                        {formatDate(quoteDetails.invoice_date)}
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
              </Typography>
              <Box
                sx={{
                  position: "relative",
                  borderBottom: "1px solid #808080",
                  my: 2,
                  textAlign: "center",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    position: "absolute",
                    top: "-10px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    backgroundColor: "white",
                    px: 1,
                    fontWeight: "bold",
                  }}
                >
                  Description
                </Typography>
              </Box>
              <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
                {quoteDetails.description}
              </Typography>
              <Box
                sx={{
                  position: "relative",
                  borderBottom: "1px solid #808080",
                  my: 2,
                  textAlign: "center",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    position: "absolute",
                    top: "-10px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    backgroundColor: "white",
                    px: 1,
                    fontWeight: "bold",
                  }}
                >
                  Files
                </Typography>
              </Box>{" "}
              <Grid container spacing={2} sx={{ mt: 2 }}>
                {/* Left Side: File Preview Buttons */}
                <Grid item xs={6}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 1, // Space between buttons
                    }}
                  >
                    {quoteDetails.quote_file && (
                      <FilePreviewButtons
                        file={quoteDetails.quote_file}
                        label="View Quote"
                        onClick={() =>
                          handlePreviewOpen(quoteDetails.quote_file)
                        }
                      />
                    )}
                    {quoteDetails.lpo_file && (
                      <FilePreviewButtons
                        file={quoteDetails.lpo_file}
                        label="View LPO"
                        onClick={() => handlePreviewOpen(quoteDetails.lpo_file)}
                      />
                    )}
                    {quoteDetails.invoice_file && (
                      <FilePreviewButtons
                        file={quoteDetails.invoice_file}
                        label="View Invoice"
                        onClick={() =>
                          handlePreviewOpen(quoteDetails.invoice_file)
                        }
                      />
                    )}
                  </Box>
                </Grid>

                {/* Right Side: Change Files Button */}
                <Grid item xs={6}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end", // Aligns the button to the right
                      alignItems: "center", // Vertically centers the button
                      height: "100%", // Makes the box take full height
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() =>
                        navigate(`/quote_files/${quoteDetails.id}`)
                      } // Navigate to quote_files with quote ID
                      startIcon={<Icons.UploadFileIcon />}
                    >
                      Change Files
                    </Button>
                  </Box>
                </Grid>
              </Grid>
              {/* Preview Modal */}
              {/* Preview Modal */}
              <Modal
                open={open}
                onClose={handlePreviewClose}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    width: "80%",
                    height: "80%",
                    maxWidth: "800px",
                    maxHeight: "600px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    overflow: "hidden", // Prevent content overflow
                  }}
                >
                  <iframe
                    src={previewFile}
                    style={{
                      width: "100%",
                      height: "100%",
                      border: "none",
                      objectFit: "contain", // Ensures content scales and maintains aspect ratio
                    }}
                    allowFullScreen
                  ></iframe>
                </Box>
              </Modal>
              <Box
                sx={{
                  paddingTop: 2,
                  position: "relative",
                  borderBottom: "1px solid #808080",
                  my: 2,
                  textAlign: "center",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    position: "absolute",
                    paddingTop: 2,
                    top: "-10px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    backgroundColor: "white",
                    px: 1,
                    fontWeight: "bold",
                  }}
                >
                  Payments
                </Typography>
              </Box>{" "}
              {quoteDetails.advance_amount ||
              quoteDetails.pay1 ||
              quoteDetails.pay2 ||
              quoteDetails.pay3 ? (
                <>
                  {quoteDetails.advance_amount > 0 && (
                    <Grid
                      container
                      spacing={2}
                      justifyContent="center"
                      sx={{ mb: 1 }}
                    >
                      <Grid item xs={6}>
                        <Typography variant="body1" sx={{ display: "flex" }}>
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "bold", mr: 0.5 }}
                          >
                            Advance:
                          </Typography>
                          &nbsp;
                          <Typography variant="body1">
                            {quoteDetails.advance_amount} {config.currency}
                          </Typography>
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1" sx={{ display: "flex" }}>
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "bold", mr: 0.5 }}
                          >
                            Date:
                          </Typography>
                          &nbsp;
                          <Typography variant="body1">
                            {quoteDetails.advance_received_date !==
                              "0000-00-00" && quoteDetails.advance_received_date
                              ? formatDate(quoteDetails.advance_received_date)
                              : "Not Available"}
                          </Typography>
                        </Typography>
                      </Grid>
                    </Grid>
                  )}

                  {quoteDetails.pay1 > 0 && (
                    <Grid
                      container
                      spacing={2}
                      justifyContent="center"
                      sx={{ mb: 1 }}
                    >
                      <Grid item xs={6}>
                        <Typography variant="body1" sx={{ display: "flex" }}>
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "bold", mr: 0.5 }}
                          >
                            Installment 1:
                          </Typography>
                          &nbsp;
                          <Typography variant="body1">
                            {quoteDetails.pay1} {config.currency}
                          </Typography>
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1" sx={{ display: "flex" }}>
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "bold", mr: 0.5 }}
                          >
                            Date:
                          </Typography>
                          &nbsp;
                          <Typography variant="body1">
                            {quoteDetails.pay1_date !== "0000-00-00" &&
                            quoteDetails.pay1_date
                              ? formatDate(quoteDetails.pay1_date)
                              : "Not Available"}
                          </Typography>
                        </Typography>
                      </Grid>
                    </Grid>
                  )}

                  {quoteDetails.pay2 > 0 && (
                    <Grid
                      container
                      spacing={2}
                      justifyContent="center"
                      sx={{ mb: 1 }}
                    >
                      <Grid item xs={6}>
                        <Typography variant="body1" sx={{ display: "flex" }}>
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "bold", mr: 0.5 }}
                          >
                            Installment 2:
                          </Typography>
                          &nbsp;
                          <Typography variant="body1">
                            {quoteDetails.pay2} {config.currency}
                          </Typography>
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1" sx={{ display: "flex" }}>
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "bold", mr: 0.5 }}
                          >
                            Date:
                          </Typography>
                          &nbsp;
                          <Typography variant="body1">
                            {quoteDetails.pay2_date !== "0000-00-00" &&
                            quoteDetails.pay2_date
                              ? formatDate(quoteDetails.pay2_date)
                              : "Not Available"}
                          </Typography>
                        </Typography>
                      </Grid>
                    </Grid>
                  )}

                  {quoteDetails.pay3 > 0 && (
                    <Grid
                      container
                      spacing={2}
                      justifyContent="center"
                      sx={{ mb: 1 }}
                    >
                      <Grid item xs={6}>
                        <Typography variant="body1" sx={{ display: "flex" }}>
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "bold", mr: 0.5 }}
                          >
                            Installment 3:
                          </Typography>
                          &nbsp;
                          <Typography variant="body1">
                            {quoteDetails.pay3} {config.currency}
                          </Typography>
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1" sx={{ display: "flex" }}>
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "bold", mr: 0.5 }}
                          >
                            Date:
                          </Typography>
                          &nbsp;
                          <Typography variant="body1">
                            {quoteDetails.pay3_date !== "0000-00-00" &&
                            quoteDetails.pay3_date
                              ? formatDate(quoteDetails.pay3_date)
                              : "Not Available"}
                          </Typography>
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </>
              ) : (
                <Typography
                  variant="body1"
                  align="center"
                  sx={{ fontWeight: "light", mt: 2 }}
                >
                  No payments received.
                </Typography>
              )}
            </Box>
          )}
          <Box
            sx={{
              position: "relative",
              borderBottom: "1px solid #808080",
              my: 2,
              textAlign: "center",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                position: "absolute",
                top: "-10px",
                left: "50%",

                transform: "translateX(-50%)",
                backgroundColor: "white",
                px: 1,
                fontWeight: "bold",
              }}
            >
              Dues
            </Typography>
          </Box>

          <Typography variant="body1" sx={{ mb: 1 }}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={6}>
                <Typography variant="body1" sx={{ display: "flex" }}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold", mr: 0.5 }}
                  >
                    Balance:
                  </Typography>
                  <Typography variant="body1">
                    {quoteDetails.approved_amount -
                      quoteDetails.advance_amount -
                      quoteDetails.pay1 -
                      quoteDetails.pay2 -
                      quoteDetails.pay3 >
                    0
                      ? `${
                          quoteDetails.approved_amount -
                          quoteDetails.advance_amount -
                          quoteDetails.pay1 -
                          quoteDetails.pay2 -
                          quoteDetails.pay3
                        } ${config.currency}`
                      : "No Balance"}
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" sx={{ display: "flex" }}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold", mr: 0.5 }}
                  >
                    Due:
                  </Typography>
                  <Typography variant="body1">
                    {quoteDetails.date_of_completion &&
                    quoteDetails.date_of_completion !== "0000-00-00"
                      ? calculateDueDate(quoteDetails.date_of_completion)
                      : "Not Available"}
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={6}>
                <Typography variant="body1" sx={{ display: "flex" }}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold", mr: 0.5 }}
                  ></Typography>
                  <Typography variant="body1"></Typography>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" sx={{ display: "flex" }}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold", mr: 0.5 }}
                  >
                    90 days:
                  </Typography>
                  <Typography variant="body1">
                    {quoteDetails.advance_received_date &&
                    quoteDetails.advance_received_date !== "0000-00-00"
                      ? calculateDueDate(quoteDetails.advance_received_date)
                      : "Not Available"}
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
          </Typography>

          {/* <Typography variant="body2">
            Created on: {new Date().toLocaleDateString()} | System Info:{" "}
            {deviceInfo} | Created by: {localStorage.username} with session{" "}
            {localStorage.session_id}
          </Typography> */}
        </Paper>
        <FloatingActionButtons />
      </Box>
    </Layout>
  );
};

export default QuoteDetails;
