import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Modal,
  Backdrop,
  Fade,
  Fab,
} from "@mui/material";
import { useParams } from "react-router-dom";
import config from "../../config";
import { useNavigate } from "react-router-dom";
import Icons from "../../utils/icon";
import Layout from "../../utils/Layout";
import { useToast } from "../../utils/ToastContext";

const SiteFilesPage = () => {
  const { id } = useParams();
  const [employee, setEmployee] = useState({});
  const [loading, setLoading] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const triggerToast = useToast();

  const [files, setFiles] = useState({
    photo: null,
    pass_back: null,
    pass_front: null,
    id_back: null,
    id_front: null,
    doc1: null,
    doc2: null,
  });

  const [previewFile, setPreviewFile] = useState(null);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        setLoading(true);

        const requestData = {
          id,
          username: localStorage.getItem("username"),
          session_id: localStorage.getItem("session_id"),
        };

        const response = await axios.post(
          `${config.apiBaseUrl}/employee/get_by_id_emp.php`,
          requestData,
          {
            headers: { "Content-Type": "application/json" },
          },
        );

        if (response.data.success) {
          const fetchedEmployee = response.data.employee;
          setEmployee(fetchedEmployee);
        } else {
          triggerToast("error", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching Employee:", error);
        triggerToast("error", "Failed to load Employee details.");
      } finally {
        setLoading(false);
      }
    };

    fetchEmployees();
  }, [id]);

  const handleFileChange = (event, type) => {
    const file = event.target.files[0];
    setFiles((prev) => ({ ...prev, [type]: file }));
  };

  const handleFileDelete = (type) => {
    setFiles((prev) => ({ ...prev, [type]: null }));
  };

  const handleFileUpload = async () => {
    const username = localStorage.getItem("username");
    const session_id = localStorage.getItem("session_id");

    if (!username || !session_id) {
      triggerToast("error", "User is not authenticated!");
      return;
    }

    try {
      setLoading(true);

      for (const [key, file] of Object.entries(files)) {
        if (file) {
          const formData = new FormData();
          formData.append("id", id);
          formData.append("session_id", session_id);
          formData.append("username", username);
          formData.append(
            "file_name",
            `${id}_${key}.${file.name.split(".").pop()}`,
          );
          formData.append("file", file);
          formData.append("file_type", key);

          const response = await fetch(
            `${config.apiBaseUrl}/upload_emp_file.php`,
            {
              method: "POST",
              body: formData,
            },
          );

          const result = await response.json();
          if (!result.success) {
            throw new Error(result.message || "File upload failed");
          }
        }
      }

      triggerToast("success", "Files uploaded successfully!");
    } catch (error) {
      console.error("Error uploading files:", error);
      triggerToast("error", "Error uploading files. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handlePreview = (fileUrl) => {
    // For PDF files, render the PDF inside an iframe or a viewer component
    if (fileUrl.endsWith(".pdf")) {
      setPreviewFile(fileUrl);
      setIsPreviewOpen(true);
    } else {
      setPreviewFile(fileUrl);
      setIsPreviewOpen(true);
    }
  };

  const closePreview = () => {
    setIsPreviewOpen(false);
    setPreviewFile(null);
  };

  // const handleBack = () => {
  //   navigate("/emp");
  // };

  const handleBack = () => {
    navigate(-1); // Navigate to the previous page
  };

  const renderFileRow = (label, key, index) => {
    const file = files[key];
    const fileUrl = employee[key];

    const isImage = file
      ? file.type.startsWith("image/")
      : fileUrl?.endsWith(".jpg") ||
        fileUrl?.endsWith(".png") ||
        fileUrl?.endsWith(".jpeg");
    const isPdf = file
      ? file.type === "application/pdf"
      : fileUrl?.endsWith(".pdf");

    const rowStyle = {
      backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#ffffff", // Alternating row colors
    };

    return (
      <TableRow
        key={key}
        sx={{
          ...rowStyle,
          height: "20px", // Reduce row height
          "& > *": {
            padding: "5px 10px", // Adjust cell padding (top-bottom: 8px, left-right: 16px)
          },
        }}
      >
        <TableCell>{label}</TableCell>
        <TableCell>
          {file || fileUrl ? (
            <IconButton
              color="primary"
              onClick={() =>
                handlePreview(file ? URL.createObjectURL(file) : fileUrl)
              }
            >
              {isImage ? (
                <img
                  src={file ? URL.createObjectURL(file) : fileUrl}
                  alt={label}
                  style={{
                    width: "30px",
                    height: "30px",
                    objectFit: "contain",
                  }}
                />
              ) : isPdf ? (
                <Icons.PictureAsPdfIcon
                  style={{ fontSize: "2rem", color: "#d32f2f" }}
                />
              ) : (
                "File"
              )}
            </IconButton>
          ) : (
            "No File Available"
          )}
        </TableCell>
        <TableCell>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px",
              border: "1px dashed #ccc",
              borderRadius: "4px",
              cursor: "pointer",
              backgroundColor: isDragging === key ? "#e0f7fa" : "transparent", // Conditional background color based on key
            }}
            onDrop={(e) => {
              e.preventDefault();
              const file = e.dataTransfer.files[0];
              handleFileChange({ target: { files: [file] } }, key);
              setIsDragging(null); // Reset dragging state
            }}
            onDragOver={(e) => {
              e.preventDefault();
              setIsDragging(key); // Set dragging state for specific key
            }}
            onDragLeave={() => setIsDragging(null)} // Reset dragging state when leaving the area
          >
            <Button
              variant="contained"
              component="label"
              color="primary"
              startIcon={<Icons.CloudUploadIcon />}
            >
              Change / Upload
              <input
                type="file"
                hidden
                onChange={(e) => handleFileChange(e, key)}
              />
            </Button>
          </div>
        </TableCell>

        <TableCell>
          {file || fileUrl ? (
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<Icons.DeleteIcon />}
              onClick={() => handleFileDelete(key)}
            >
              Clear
            </Button>
          ) : null}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          height: "85vh",
          maxWidth: "md",
          margin: "auto",
          overflow: "hidden",
        }}
      >
        {/* <Typography variant="h4" gutterBottom>
          Employee Files: {employee.given_name}
        </Typography> */}
        <TableContainer
          sx={{
            flexGrow: 1,
            height: "calc(100vh - 90px)",
            overflow: "auto",
            "&::-webkit-scrollbar": {
              width: "5px",
              height: "5px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              borderRadius: "10px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.7)",
            },
          }}
        >
          <Table
            stickyHeader
            sx={{
              tableLayout: "auto",
              width: "md",
            }}
          >
            {" "}
            <TableHead>
              <TableRow>
                <TableCell>File Name</TableCell>
                <TableCell>Preview</TableCell>
                <TableCell>Options</TableCell>
                <TableCell>Clear</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {renderFileRow("Photo", "photo")}
              {renderFileRow("Passport Front", "pass_front")}
              {renderFileRow("Passport Back", "pass_back")}
              {renderFileRow("ID Front", "id_front")}
              {renderFileRow("ID Back", "id_back")}
              {renderFileRow("Document 1", "doc1")}
              {renderFileRow("Document 2", "doc2")}
            </TableBody>
          </Table>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: 2, // Creates a small gap between buttons
              mt: 3, // Adds margin to separate it from the content above
            }}
          >
            {" "}
            <Button
              variant="contained"
              color="primary"
              onClick={handleFileUpload}
              disabled={loading}
              sx={{
                flex: 1,
              }}
            >
              {loading ? "Uploading..." : "Upload Files"}
            </Button>
            <Button
              type="button"
              variant="outlined"
              color="secondary"
              onClick={handleBack}
              sx={{
                flex: 1,
              }}
            >
              Cancel
            </Button>
          </Box>
        </TableContainer>
        {/* Preview Modal */}
        <Modal
          open={isPreviewOpen}
          onClose={closePreview}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={isPreviewOpen}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "80%",
                bgcolor: "transparent",
                border: "0px solid #000",
                boxShadow: 0,
                p: 4,
              }}
            >
              {previewFile ? (
                previewFile.endsWith(".pdf") ? (
                  <iframe
                    src={previewFile}
                    width="100%"
                    height="auto"
                    maxHeight="80vh"
                    style={{ border: "none" }}
                    title="PDF Preview"
                  />
                ) : (
                  <img
                    src={previewFile}
                    alt="Preview"
                    style={{
                      width: "100%",
                      height: "auto",
                      maxHeight: "80vh",
                      objectFit: "contain",
                    }}
                  />
                )
              ) : (
                "No Preview Available"
              )}
            </Box>
          </Fade>
        </Modal>
        ;
      </Box>
    </Layout>
  );
};

export default SiteFilesPage;
