import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  AppBar,
  Toolbar,
  Button,
  Drawer,
  IconButton,
  Divider,
  useMediaQuery,
  Avatar,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import CloseIcon from "@mui/icons-material/Close";
import SettingsIcon from "@mui/icons-material/Settings";

import {
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
} from "@mui/icons-material";

import LogoutIcon from "@mui/icons-material/Logout";
import { useLocation, useNavigate } from "react-router-dom";
import { handleLogout } from "./auth";
import { useTheme } from "@mui/material/styles";
import config from "../config";
import Icons from "./icon";
import { useToast } from "./ToastContext";
import routes from "../routes";

const Layout = ({ children }) => {
  const navigate = useNavigate();
  const triggerToast = useToast(); // Access the triggerToast function
  const location = useLocation();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md")); // Medium screens
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm")); // Small screens
  const isDashboard = location.pathname === "/dashboard"; // Check if the route is /dashboard

  const [drawerBackground, setDrawerBackground] = useState(
    localStorage.getItem("drawerBackground") || "#212121"
  );

  const [appBarBackground, setAppBarBackground] = useState(
    localStorage.getItem("appBarBackground") || "#1976d2" // Default AppBar color
  );

  const [isDrawerOpen, setIsDrawerOpen] = useState(() => {
    // Check if the drawer state is stored in local storage
    const storedState = localStorage.getItem("drawerOpen");
    if (storedState === null) {
      // If not found, store it as open and return true
      localStorage.setItem("drawerOpen", JSON.stringify(true));
      return true;
    }
    // Parse and return the stored value
    return JSON.parse(storedState);
  });
  const [pageTitle, setPageTitle] = useState("Dashboard");
  const [username, setUsername] = useState("");

  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md")); // Detects md (960px) and below

  // Set the username on initial load
  useEffect(() => {
    const storedUser = localStorage.getItem("username");
    setUsername(storedUser || "Guest");
  }, []);

  useEffect(() => {
    const matchedRoute = routes.find((route) => {
      const pathRegex = new RegExp(
        "^" + route.path.replace(/:\w+/g, "([\\w-]+)") + "$"
      );
      return pathRegex.test(location.pathname);
    });
    if (matchedRoute) {
      setPageTitle(matchedRoute.title);
    } else {
      setPageTitle("Dashboard");
    }
  }, [location.pathname]);

  useEffect(() => {
    const savedState = JSON.parse(localStorage.getItem("drawerOpen"));

    if (isLargeScreen) {
      // If on large screens, respect the previous state but do not force open
      setIsDrawerOpen(savedState !== null ? savedState : true);
    } else {
      // On small screens, close the drawer and save the state
      const savedState = JSON.parse(localStorage.getItem("drawerOpen"));
      setIsDrawerOpen(savedState !== null ? savedState : false);
    }
  }, [isLargeScreen]);

  const toggleDrawer = () => {
    const newState = !isDrawerOpen;
    setIsDrawerOpen(newState);
    localStorage.setItem("drawerOpen", JSON.stringify(newState));
  };

  const handleNavigation = (path) => {
    navigate(path);
    if (!isLargeScreen) {
      setIsDrawerOpen(false); // Close the drawer
      localStorage.setItem("drawerOpen", JSON.stringify(false)); // Save the state
    }
  };

  const onLogout = async () => {
    await handleLogout();
    navigate("/");
  };

  const onSettings = async () => {
    navigate("/settings", { replace: false });
  };

  const menuItems = routes
    .filter((route) => route.mainPage)
    .map((route) => ({
      text: route.title,
      icon: route.icon,
      path: route.path,
    }));

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const getInitials = (username) => {
    const nameParts = username.split(" ");
    if (nameParts.length > 1) {
      return (
        nameParts[0].charAt(0).toUpperCase() +
        nameParts[nameParts.length - 1].charAt(0).toUpperCase()
      );
    }
    return nameParts[0].charAt(0).toUpperCase(); // If only one word
  };

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      {/* Header */}
      <AppBar
        position="fixed"
        sx={{ backgroundColor: appBarBackground, zIndex: 1300 }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton color="inherit" onClick={toggleDrawer} edge="start">
              {isDrawerOpen ? <CloseIcon /> : <MenuIcon />}
            </IconButton>

            {/* iPhone-style Back Button (Only for Small Screens) */}
            {!isLargeScreen && !isDashboard && (
              <IconButton
                color="inherit"
                onClick={() => navigate(-1)}
                sx={{
                  mr: 1,
                  backgroundColor: "rgba(255, 255, 255, 0.2)",
                  borderRadius: "12px",
                  padding: "6px 12px",
                  display: "flex",
                  alignItems: "center",
                  gap: 0.5,
                  "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.3)",
                  },
                }}
              >
                <ArrowBackIcon fontSize="small" />
                <Typography variant="body2" sx={{ fontSize: "14px" }}>
                  Back
                </Typography>
              </IconButton>
            )}

            {/* Conditionally render logo only on large screens */}
            {isLargeScreen && (
              <Box
                component="img"
                src="/assets/logo.png"
                alt="Company Logo"
                sx={{ height: 40, marginLeft: 1 }}
              />
            )}
          </Box>
          <Typography variant="h6" sx={{ textAlign: "center", flexGrow: 1 }}>
            {pageTitle}
          </Typography>

          <IconButton
            color="inherit"
            onClick={() => navigate("/dashboard")}
            sx={{ ml: 1 }}
          >
            <HomeIcon />
          </IconButton>

          {isLargeScreen && ( // Only show Logout on large screens
            <Button
              color="inherit"
              startIcon={<LogoutIcon />}
              onClick={onLogout}
            >
              Logout
            </Button>
          )}
        </Toolbar>
      </AppBar>

      {/* Sidebar Drawer */}
      <Drawer
        variant={isLargeScreen ? "persistent" : "temporary"}
        anchor="left"
        open={isDrawerOpen}
        onClose={!isLargeScreen ? toggleDrawer : undefined}
        sx={{
          "& .MuiDrawer-paper": {
            width: isDrawerOpen ? 250 : isLargeScreen ? 70 : 0,
            backgroundColor: drawerBackground, // Use the selected color here
            color: "white",
            top: isLargeScreen ? "64px" : 0, // Handle position based on screen size
            transition: "width 0.3s ease",
            overflowY: "auto",
            height: "100%",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%", // Ensure content spans the full height
          }}
        >
          {/* User Info */}
          {isDrawerOpen && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center", // Vertically center the items
                justifyContent: "start", // Optional: Horizontally center the items
                p: 2,
                mt: isLargeScreen ? 0 : "64px", // Adjust margin for small screens
              }}
            >
              <Avatar
                sx={{
                  width: 30,
                  height: 30,
                  marginRight: 1,
                  backgroundColor: "#3f51b5",
                }}
              >
                {getInitials(username)}
              </Avatar>
              <Typography variant="h6" sx={{ flexGrow: 1 }}>
                {username}
              </Typography>
              {/* Settings Icon */}
              <IconButton
                color="inherit"
                onClick={() => {
                  onSettings();
                  if (isMobileOrTablet) {
                    setIsDrawerOpen(false);
                    localStorage.setItem("drawerOpen", JSON.stringify(false));
                  }
                }}
                sx={{
                  color: "white",
                  marginLeft: "10px",
                }}
              >
                <SettingsIcon />
              </IconButton>
            </Box>
          )}
          <Divider sx={{ borderColor: "white", opacity: 0.5 }} />

          {/* Menu Buttons */}
          <Box sx={{ p: 2, flexGrow: 1 }}>
            {menuItems.map((item) => (
              <Button
                key={item.text}
                fullWidth
                onClick={() => handleNavigation(item.path)}
                startIcon={isDrawerOpen ? item.icon : null}
                sx={{
                  mb: 2,
                  justifyContent: isDrawerOpen ? "flex-start" : "center",
                  color: "white",
                  textTransform: "none",
                  padding: 0.5,
                  paddingLeft: 1.5,
                  backgroundColor:
                    location.pathname === item.path
                      ? "rgba(255, 255, 255, 0.2)"
                      : "transparent",
                  fontSize: isDrawerOpen ? "1rem" : 0,
                  transition: "all 0.3s ease", // Smooth transition for all properties
                  "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.3)",
                    transform: "scale(1.05)", // Slight scale effect for hover
                    "& .MuiButton-startIcon": {
                      marginRight: 1, // Space between the icon and text
                      transform: "scale(1.2)", // Increase icon size on hover
                      transition: "transform 0.3s ease", // Smooth transition for the icon size
                    },
                    "& .MuiButton-label": {
                      opacity: 0.8, // Slight fading effect on text during hover
                      transition: "opacity 0.3s ease", // Smooth transition for opacity
                    },
                  },
                }}
              >
                {isDrawerOpen && item.text}
              </Button>
            ))}
          </Box>

          {/* Logout Button */}
          <Box
            sx={{
              textAlign: "center",
              pb: { xs: 2, md: 10 }, // Set padding bottom to 2 for small screens and 10 for large screens
            }}
          >
            <Button
              fullWidth
              color="inherit"
              startIcon={<LogoutIcon />}
              onClick={onLogout}
              sx={{
                textTransform: "none",
                padding: 1.5,
                "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.3)" },
              }}
            >
              Logout
            </Button>
          </Box>
        </Box>
      </Drawer>

      {/* Main Content */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          marginLeft: isLargeScreen && isDrawerOpen ? "250px" : 0,
          padding: 1,
          marginTop: isSmallScreen ? "55px" : "64px", // Set marginTop based on screen size
          backgroundColor: config.layoutColors.background,
          overflowY: "hidden",
          transition: "none",
        }}
      >
        {children}
      </Box>

      {/* Bottom App Bar only for small screen */}
      {(isMediumScreen || isSmallScreen) && (
        <AppBar
          position="fixed"
          sx={{
            top: "auto",
            bottom: 0,
            backgroundColor: config.layoutColors.appBar,
            height: "5px",
          }}
        >
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "5px",
            }}
          ></Toolbar>
        </AppBar>
      )}
    </Box>
  );
};

export default Layout;
