import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  Button,
  Box,
  Typography,
  Container,
  Divider,
  IconButton,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useToast } from "../../utils/ToastContext";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Layout from "../../utils/Layout";
import config from "../../config";

const EditAttendance = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [siteList, setSiteList] = useState([]); // To store site names
  const [empList, setEmpList] = useState([]); // List of Attendance name
  const triggerToast = useToast();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [formData, setFormData] = useState({
    id: id,
    siteId: "",
    empId: "",
    present: "",
    mess: "",
    wifi: "",
    advance: "",
    visa: "",
    totalReduction: "",
    visaRemarks: "",
    date: "", // Add this field
    username: localStorage.username,
    session_id: localStorage.session_id,
  });

  // Fetch site names for the dropdown
  useEffect(() => {
    const fetchEmpNames = async () => {
      try {
        const response = await axios.post(
          `${config.apiBaseUrl}/employee/get_emp_list.php`,
          {
            username: localStorage.username,
            session_id: localStorage.session_id,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.success) {
          setEmpList(response.data.data); // Assuming the data is an array of { id, surname }
        } else {
          triggerToast("error", response.data.message); // Show error message
        }
      } catch (error) {
        console.error("Error fetching Emp names:", error);
        triggerToast("error", "Failed to load Emp names.");
      }
    };

    fetchEmpNames();
  }, []);

  // Fetch site names from the API
  useEffect(() => {
    const fetchSiteNames = async () => {
      try {
        const response = await axios.post(
          `${config.apiBaseUrl}/site/get_site_list.php`,
          {
            username: localStorage.username,
            session_id: localStorage.session_id,
          },
          {
            headers: {
              "Content-Type": " application/json",
            },
          }
        );

        if (response.data.success) {
          setSiteList(response.data.data); // Assuming the data is an array of { id, surname }
        } else {
          triggerToast("error", response.data.message); // Show error message
        }
      } catch (error) {
        console.error("Error fetching site names:", error);
        triggerToast("error", "Failed to load site names.");
      }
    };

    fetchSiteNames();
  }, []);

  // Fetch existing attendance data for editing
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const requestData = {
          id,
          username: localStorage.getItem("username"),
          session_id: localStorage.getItem("session_id"),
        };

        const response = await axios.post(
          `${config.apiBaseUrl}/attendance/get_by_id_attendance.php`,
          requestData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data.success) {
          // triggerToast("success", "Attendance Updated successfully!");
          const fetchedData = response.data.attendance;
          setFormData((prevData) => ({
            ...prevData,
            siteId: fetchedData.site_id, // Map site_id to siteId
            empId: fetchedData.emp_id, // Map emp_id to empId
            present: fetchedData.present,
            mess: fetchedData.mess,
            wifi: fetchedData.wifi,
            advance: fetchedData.advance,
            visa: fetchedData.visa,
            totalReduction: fetchedData.total_reduction,
            visaRemarks: fetchedData.visa_remarks,
            date: fetchedData.date,
          }));
        } else {
          triggerToast(
            "error",
            response.data.message || "Failed to fetch attendance data."
          );
        }
      } catch (error) {
        console.error("Error fetching attendance data:", error);
        triggerToast(
          "error",
          "An error occurred while fetching attendance data."
        );
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Update formData
    setFormData((prevData) => {
      const updatedData = { ...prevData, [name]: value };

      // Convert values to numbers (default to 0 if empty)
      const mess = Number(updatedData.mess) || 0;
      const wifi = Number(updatedData.wifi) || 0;
      const advance = Number(updatedData.advance) || 0;
      const visa = Number(updatedData.visa) || 0;

      // Calculate total reduction
      updatedData.totalReduction = mess + wifi + advance + visa;

      return updatedData;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const updatedFormData = {
        ...formData,
        id: id,
        username: localStorage.username,
        session_id: localStorage.session_id,
      };

      const response = await axios.post(
        `${config.apiBaseUrl}/attendance/update_attendance.php`,
        updatedFormData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.success) {
        triggerToast("success", response.data.message);
        navigate("/attendance");

        // setTimeout(() => {
        // }, 2000);
      } else {
        triggerToast("error", response.data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      triggerToast("error", "Something went wrong!");
    }
  };

  // const handleBack = () => {
  //   navigate("/attendance");
  // };

  const handleBack = () => {
    navigate(-1); // Navigate to the previous page
  };

  return (
    <Layout>
      <Container
        maxWidth="md"
        style={{
          overflow: "auto",
          maxHeight: "90vh",
          marginBottom: 10,
          scrollbarWidth: "thin",
          scrollbarColor: "lightgrey transparent",
          padding: "16px",
          border: "1px solid #e0e0e0",
          borderRadius: "8px",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#ffffff",
        }}
      >
        {!isMobile && ( // Hide on mobile
          <Box display="flex" alignItems="center" mb={3}>
            <IconButton onClick={handleBack}>
              <ArrowBack />
            </IconButton>
            <Typography variant="h5" ml={1}>
              Edit Attendance
            </Typography>
          </Box>
        )}
        <form onSubmit={handleSubmit}>
          <Box mb={4}>
            <Typography variant="h6" gutterBottom>
              Attendance Details
            </Typography>
            <Divider />
            <Box display="flex" flexDirection="column" gap={2} mb={2} mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  {/* Dropdown for Emp Names */}
                  <TextField
                    select
                    label="Emp Name"
                    variant="outlined"
                    name="empId"
                    value={formData.empId}
                    onChange={handleChange}
                    required
                    fullWidth
                  >
                    {empList.map((emp) => (
                      <MenuItem key={emp.id} value={emp.id}>
                        {emp.surname}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    select
                    label="Site Name"
                    variant="outlined"
                    name="siteId"
                    value={formData.siteId}
                    onChange={handleChange}
                    required
                    fullWidth
                  >
                    {siteList.map((site) => (
                      <MenuItem key={site.id} value={site.id}>
                        {site.site_name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>

              <TextField
                label="Date"
                type="date"
                variant="outlined"
                name="date"
                value={formData.date || new Date().toISOString().split("T")[0]} // Set current date by default
                onChange={handleChange}
                required
                fullWidth
                InputLabelProps={{ shrink: true }}
              />

              <Grid container spacing={2}>
                {/* Present */}
                <Grid item xs={4}>
                  <TextField
                    label="Present"
                    variant="outlined"
                    name="present"
                    // type="number"
                    value={formData.present}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>

                {/* Mess */}
                <Grid item xs={4}>
                  <TextField
                    label="Mess"
                    variant="outlined"
                    name="mess"
                    // type="number"
                    value={formData.mess}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>

                {/* WiFi */}
                <Grid item xs={4}>
                  <TextField
                    label="WiFi"
                    variant="outlined"
                    name="wifi"
                    // type="number"
                    value={formData.wifi}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                {/* Advance */}
                <Grid item xs={4}>
                  <TextField
                    label="Advance"
                    variant="outlined"
                    name="advance"
                    // type="number"
                    value={formData.advance}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>

                {/* Visa */}
                <Grid item xs={4}>
                  <TextField
                    label="Visa"
                    variant="outlined"
                    name="visa"
                    // type="number"
                    value={formData.visa}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>

                {/* Total Reduction */}
                <Grid item xs={4}>
                  <TextField
                    label="Total Reduction"
                    variant="outlined"
                    name="totalReduction"
                    // type="number"
                    value={formData.totalReduction}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
              </Grid>

              {/* Visa Remarks */}
              <TextField
                label="Visa Remarks"
                variant="outlined"
                name="visaRemarks"
                value={formData.visaRemarks}
                onChange={handleChange}
                multiline
                rows={2}
                fullWidth
              />
            </Box>
          </Box>

          {/* Buttons */}
          <Box mt={4} display="flex" justifyContent="space-between">
            <Button
              type="button"
              variant="outlined"
              color="secondary"
              onClick={handleBack}
              style={{ flex: 1, marginRight: "8px" }}
            >
              Cancel
            </Button>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ flex: 1 }}
              disabled={loading} // Disable button while loading
            >
              {loading ? "Updating..." : "Save"}
            </Button>
          </Box>
        </form>
      </Container>
    </Layout>
  );
};

export default EditAttendance;
