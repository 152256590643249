import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  TableSortLabel,
  Fab,
  Checkbox,
  MenuItem,
  IconButton,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useNavigate } from "react-router-dom";
import Icons from "./icon";

import Layout from "./Layout";
import { formatDate } from "./format";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import config from "../config";
import { useToast } from "./ToastContext";

import {
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
} from "@mui/icons-material";

const months = [
  { value: "1", label: "January" },
  { value: "2", label: "February" },
  { value: "3", label: "March" },
  { value: "4", label: "April" },
  { value: "5", label: "May" },
  { value: "6", label: "June" },
  { value: "7", label: "July" },
  { value: "8", label: "August" },
  { value: "9", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
];

const SalaryTable = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [showSearchBar, setShowSearchBar] = useState(false);

  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [data, setData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const navigate = useNavigate();
  const currentDate = new Date();
  const currentMonth = (currentDate.getMonth() + 1).toString(); // Add 1 since months are 0-indexed
  const currentYear = currentDate.getFullYear().toString();

  const [month, setMonth] = useState(currentMonth);
  const [year, setYear] = useState(currentYear);

  const [empList, setEmpList] = useState([]);
  const [basicPayData, setBasicPayData] = useState({});
  const [attendanceData, setAttendanceData] = useState({});

  const [loading, setLoading] = useState(true);

  const triggerToast = useToast();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const username = localStorage.getItem("username");
        const session_id = localStorage.getItem("session_id");

        if (!username || !session_id) {
          navigate("/");
          return;
        }

        // Fetch employee list
        const empListResponse = await axios.post(
          `${config.apiBaseUrl}/employee/get_emp_list_nick.php`,
          { username, session_id },
        );

        if (empListResponse.data.success) {
          setEmpList(empListResponse.data.data);
        } else {
          triggerToast("error", empListResponse.data.message);
        }

        // Fetch basic pay
        const basicPayResponse = await axios.post(
          `${config.apiBaseUrl}/employee/fetch_basic_pay.php`,
          { username, session_id },
        );

        if (basicPayResponse.data.success) {
          const basicPayMap = basicPayResponse.data.data.reduce(
            (acc, { empid, basicpay }) => {
              acc[empid] = basicpay;
              return acc;
            },
            {},
          );
          setBasicPayData(basicPayMap);
        } else {
          triggerToast("error", basicPayResponse.data.message);
        }

        // Fetch attendance records
        const attendanceResponse = await axios.post(
          `${config.apiBaseUrl}/attendance/get_all_attendance.php`,
          { username, session_id, month, year },
        );

        if (attendanceResponse.data.success) {
          const attendanceMap = attendanceResponse.data.data.reduce(
            (acc, record) => {
              if (!acc[record.emp_id]) acc[record.emp_id] = [];
              acc[record.emp_id].push(record);
              return acc;
            },
            {},
          );
          setAttendanceData(attendanceMap);
        } else {
          triggerToast("error", attendanceResponse.data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        triggerToast("error", "Failed to load data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [month, year]);

  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction });
  };

  const handleCheckboxChange = (id) => {
    setSelectedData((prev) =>
      prev.includes(id)
        ? prev.filter((dataId) => dataId !== id)
        : [...prev, id],
    );
  };

  // Move to the previous month
  const handlePrevMonth = () => {
    let prevMonth = parseInt(month) - 1;
    let prevYear = year;

    if (prevMonth < 1) {
      prevMonth = 12;
      prevYear = (parseInt(year) - 1).toString();
    }

    setMonth(prevMonth.toString());
    setYear(prevYear);
  };

  // Move to the next month
  const handleNextMonth = () => {
    let nextMonth = parseInt(month) + 1;
    let nextYear = year;

    if (nextMonth > 12) {
      nextMonth = 1;
      nextYear = (parseInt(year) + 1).toString();
    }

    setMonth(nextMonth.toString());
    setYear(nextYear);
  };

  // Filtered data based on month, year, and search query
  const filteredData = data.filter((item) => {
    const itemDate = new Date(item.date);
    const matchesMonth = month
      ? itemDate.getMonth() + 1 === parseInt(month)
      : true;
    const matchesYear = year
      ? itemDate.getFullYear().toString() === year
      : true;
    const matchesSearch = item.site_name
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    return matchesMonth && matchesYear && matchesSearch;
  });

  const sortedData = filteredData.sort((a, b) => {
    const order = sortConfig.direction === "asc" ? 1 : -1;
    if (a[sortConfig.key] < b[sortConfig.key]) return -order;
    if (a[sortConfig.key] > b[sortConfig.key]) return order;
    return 0;
  });

  const handleExportPDF = () => {
    if (selectedData.length === 0) {
      console.warn("No data selected for export.");
      return;
    }
    const doc = new jsPDF();
    const tableData = selectedData.map((id) => {
      const transaction = data.find((item) => item.id === id);
      return [
        transaction.id,
        transaction.site_name,
        transaction.amount,
        transaction.date,
        transaction.remarks,
      ];
    });

    doc.text("Salary Report", 14, 10);
    doc.autoTable({
      head: [["Id", "Site Name", "Amount", "Date", "Remarks"]],
      body: tableData,
    });

    doc.save("Salary_List.pdf");
  };

  const theme = createTheme({
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: "4px",
          },
        },
      },
    },
  });

  const cellStyles = {
    border: "none",
    boxShadow: "0px 0px 0px 0.5px rgba(127, 147, 184, 0.2)",
  };

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          height: "85vh",
          maxWidth: "md",
          margin: "auto",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            width: "100%",
            position: "sticky",
            top: 0,
            zIndex: 2,
            backgroundColor: "white",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            display: "flex",
            justifyContent: "Center",
            alignItems: "center",
            paddingRight: 20,
            paddingLeft: 20,
            paddingBottom: 1,
            paddingTop: 1,
            gap: 1,
          }}
        >
          <Fab
            color="primary"
            aria-label="export"
            onClick={handleExportPDF}
            disabled={selectedData.length === 0}
            sx={{
              borderRadius: 1,
              width: 35,
              height: 35,
              minHeight: "unset",
            }}
          >
            <Icons.Download />
          </Fab>

          {/* Previous Month Button */}
          <Fab
            color="primary"
            aria-label="previous"
            onClick={handlePrevMonth}
            sx={{
              borderRadius: 1,
              width: 35,
              height: 35,
              minHeight: "unset",
            }}
          >
            <ArrowBackIcon />
          </Fab>

          <TextField
            variant="outlined"
            select
            label="Month"
            value={month}
            onChange={(e) => setMonth(e.target.value)}
            size="small"
            sx={{ flexGrow: 0.5, marginLeft: 0 }}
          >
            {months.map((month) => (
              <MenuItem key={month.value} value={month.value}>
                {month.label}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            variant="outlined"
            select
            label="Year"
            value={year}
            onChange={(e) => setYear(e.target.value)}
            size="small"
            sx={{ flexGrow: 0.5, marginLeft: 1 }}
          >
            {[...Array(3)].map((_, index) => {
              const yearOption = (parseInt(currentYear) - index).toString();
              return (
                <MenuItem key={yearOption} value={yearOption}>
                  {yearOption}
                </MenuItem>
              );
            })}
          </TextField>

          <Fab
            color="primary"
            aria-label="next"
            onClick={handleNextMonth}
            sx={{
              borderRadius: 1,
              width: 35,
              height: 35,
              minHeight: "unset",
            }}
          >
            <ArrowForwardIcon />
          </Fab>

          <Fab
            color="primary"
            aria-label="add"
            onClick={() => navigate("/add_attendance")}
            sx={{
              borderRadius: 1,
              width: 35,
              height: 35,
              minHeight: "unset",
            }}
          >
            <Icons.Add />
          </Fab>
        </Box>

        {loading ? (
          <div className="flex justify-center items-center">
            <CircularProgress />
            <p className="ml-4">Loading data... for {month}, { year}</p>
          </div>
        ) : (
          <ThemeProvider theme={theme}>
            <TableContainer
              sx={{
                flexGrow: 1,
                height: "calc(100vh - 90px)",
                overflow: "auto",
                "&::-webkit-scrollbar": {
                  width: "5px",
                  height: "5px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  borderRadius: "10px",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                },
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Nickname</TableCell>
                    <TableCell>Basic</TableCell>
                    {/* <TableCell>Date</TableCell> */}
                    <TableCell>Site</TableCell>
                    <TableCell>Present</TableCell>
                    <TableCell>Mess</TableCell>
                    <TableCell>Advance</TableCell>
                    <TableCell>Visa</TableCell>
                    <TableCell>Reduction</TableCell>
                    <TableCell>Salary</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {empList.map((emp, index) => {
                    const attendanceRecords = attendanceData[emp.id] || [{}]; // Ensure at least one row
                    return attendanceRecords.map((record, i) => {
                      const basicPay = parseFloat(basicPayData[emp.id]) || 0;
                      const present = parseInt(record.present) || 0;
                      const totalReduction =
                        parseFloat(record.total_reduction) || 0;

                      // Salary calculation
                      const salary = present * basicPay - totalReduction;

                      return (
                        <TableRow
                          key={`${emp.id}-${i}`}
                          style={{
                            backgroundColor: i === 0 ? "#fff" : "#f9f9f9",
                          }}
                        >
                          {i === 0 && (
                            <>
                              <TableCell rowSpan={attendanceRecords.length}>
                                {index + 1}
                              </TableCell>
                              <TableCell rowSpan={attendanceRecords.length}>
                                {emp.nickname}
                              </TableCell>
                              <TableCell rowSpan={attendanceRecords.length}>
                                {basicPay}
                              </TableCell>
                            </>
                          )}
                          {/* <TableCell>{record.date || "-"}</TableCell> */}
                          <TableCell>{record.site_id || "-"}</TableCell>
                          <TableCell>{present}</TableCell>
                          <TableCell>{record.mess || "-"}</TableCell>
                          <TableCell>{record.advance || "-"}</TableCell>
                          <TableCell>{record.visa || "-"}</TableCell>
                          <TableCell>{totalReduction}</TableCell>
                          <TableCell>{salary.toFixed(3)}</TableCell>
                        </TableRow>
                      );
                    });
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </ThemeProvider>
        )}
      </Box>
    </Layout>
  );
};

export default SalaryTable;
