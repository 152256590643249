import { useEffect, useState } from "react";
import axios from "axios";
import config from "../config";
import {
  Button,
  Box,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
} from "@mui/material";
import { useToast } from "./ToastContext";
import Layout from "./Layout";
import { useNavigate } from "react-router-dom";
import { formatPhoneNumber } from "./format";
import { formatExpiry } from "./format";
import { formatCardNumber } from "./format";

export default function EmployeeBankDetails() {
  const [empList, setEmpList] = useState([]);
  const [loading, setLoading] = useState(true);
  const triggerToast = useToast();
  const navigate = useNavigate();
  const [containerHeight, setContainerHeight] = useState("auto");

  useEffect(() => {
    const updateHeight = () => {
      const containerTopOffset = document.getElementById("content-container")?.offsetTop || 0;
      setContainerHeight(`calc(100vh - ${containerTopOffset + 40}px)`);
    };

    updateHeight();
    window.addEventListener("resize", updateHeight);

    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  useEffect(() => {
    const fetchEmpDetails = async () => {
      try {
        const response = await axios.post(
          `${config.apiBaseUrl}/employee/fetch_bank_details.php`,
          {
            username: localStorage.username,
            session_id: localStorage.session_id,
          },
          {
            headers: { "Content-Type": "application/json" },
          }
        );

        if (response.data.success) {
          setEmpList(response.data.data);
        } else {
          triggerToast("error", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching employee data:", error);
        triggerToast("error", "Failed to load employee data.");
      } finally {
        setLoading(false);
      }
    };

    fetchEmpDetails();
  }, []);

  const getExpiryBackground = (expiry) => {
    if (!expiry) return {}; // No expiry date, no background change
  
    const parts = expiry.split("-");
    if (parts.length < 2) return {}; // Invalid format, no background change
  
    // Extract year and month
    const expiryDate = new Date(parts[0], parts[1] - 1); // months are 0-indexed in JS
    const currentDate = new Date();
  
    // Calculate the difference in months and days
    const diffInMonths = (expiryDate.getFullYear() - currentDate.getFullYear()) * 12 + expiryDate.getMonth() - currentDate.getMonth();
    const diffInDays = Math.floor((expiryDate - currentDate) / (1000 * 3600 * 24));
  
    // Determine the background color based on the expiry date
    let backgroundColor = "";
  
    if (diffInMonths <= 0 && diffInDays <= 0) {
      // Expiry is in the past or today
      backgroundColor = "pink";
    } else if (diffInMonths <= 1) {
      // Expiry is within 1 month
      backgroundColor = "orange";
    } else if (diffInMonths <= 3) {
      // Expiry is within 3 months
      backgroundColor = "yellow";
    }
  
    return { backgroundColor: backgroundColor, padding: "2px 5px", borderRadius: "4px" }; // Add some padding for visual clarity
  };
  

  

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Layout>
      <Container
        id="content-container"
        maxWidth="lg"
        style={{
          overflow: "auto",
          height: containerHeight,
          padding: "16px",
          border: "1px solid #e0e0e0",
          borderRadius: "8px",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#ffffff",
        }}
      >
       
        {loading ? (
          <div className="flex justify-center items-center">
            <CircularProgress />
            <p className="ml-4">Loading employee data...</p>
          </div>
        ) : (
          <TableContainer style={{ maxHeight: "75vh", overflowY: "auto", padding: 0 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow style={{ backgroundColor: "#f5f5f5" }}>
                  <TableCell>#</TableCell>
                  <TableCell>Nickname</TableCell>
                  <TableCell>PIN</TableCell>
                  <TableCell>Account Details</TableCell>
                  <TableCell>Card Details</TableCell>
                  <TableCell>Phone Number</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {empList.map((emp, index) => (
                  <TableRow
                    key={emp.id}
                    style={{
                      backgroundColor: index % 2 === 0 ? "#ffffff" : "#f5f5f5",
                    }}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{emp.nickname}</TableCell>
                    <TableCell>{emp.pin || "N/A"}</TableCell>
                  
                    <TableCell>
  <div style={{ whiteSpace: "nowrap" }}>
    {formatCardNumber(emp.acc_number) || "N/A"}
  </div>
  
  {emp.acc_number && emp.acc_number !== "N/A" && (
    <div style={{ fontStyle: "italic", fontSize: "0.9rem", color: "grey", whiteSpace: "nowrap" }}>
      Branch: {emp.branch || "N/A"}
    </div>
  )}
</TableCell>

                  
                    <TableCell>
  <div>{formatCardNumber(emp.card_number) || "N/A"}</div>
  {emp.card_number && (  // Conditionally render the second line only if card_number exists
    <div 
      style={{ 
        fontStyle: "italic", 
        fontSize: "0.9rem", 
        color: "grey", 
        whiteSpace: "nowrap" // Prevent text wrapping
      }}
    >
      Exp: 
      <span style={getExpiryBackground(emp.card_expiry)}>
        {formatExpiry(emp.card_expiry)} 
      </span>
      | CVV: {emp.cvv || "N/A"}
    </div>
  )}
</TableCell>



                    <TableCell>{formatPhoneNumber(emp.phone_number) || "N/A"}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <Box mt={4} display="flex" justifyContent="space-between">
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleBack}
            style={{ flex: 1, marginRight: "8px" }}
          >
            Back
          </Button>
        </Box>
      </Container>
    </Layout>
  );
}
