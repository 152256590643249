const config = {
  apiBaseUrl: "https://quo.pixolab.in/auth",
  layoutColors: {
    appBar: "#212121", // AppBar color
    drawer: "#212121", // Drawer background color
    background: "#f9f9f9", // Main content background color
  },
  currency: "OMR", // Currency value
};

export default config;

// blue
// appBar: "#1976d2", // AppBar color
// drawer: "#1976d2", // Drawer background color
// background: "#f9f9f9", // Main content background color
