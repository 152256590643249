import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles"; // Import the necessary MUI components
import routes from "./routes"; // Import the routes configuration file

// Create a default theme or import an existing theme configuration
const theme = createTheme({
  // Define your theme customization here
  palette: {
    primary: {
      main: "#1976d2", // Example primary color
    },
    secondary: {
      main: "#dc004e", // Example secondary color
    },
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      {" "}
      {/* Wrap your application with ThemeProvider */}
      <Router>
        <Routes>
          {routes.map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
