import React, { useState, useEffect, useRef } from "react";
import { Box, Typography, Button, Grid, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ChromePicker } from "react-color"; // Import ChromePicker from react-color
import Layout from "./Layout";
import CloseIcon from "@mui/icons-material/Close"; // Import CloseIcon

const colorCombinations = [
  { drawer: "#1D3557", appBar: "#A8DADC" }, // Deep Blue & Light Blue
  { drawer: "#FFC107", appBar: "#1D3557" }, // Off White & Deep Blue
  { drawer: "#2A9D8F", appBar: "#E9C46A" }, // Teal & Yellow
  { drawer: "#264653", appBar: "#E76F51" }, // Dark Blue & Coral
  { drawer: "#F4A261", appBar: "#2A9D8F" }, // Orange & Teal
  { drawer: "#E63946", appBar: "#FFC107" }, // Red & Off White
  { drawer: "#6D9DC5", appBar: "#F1E3D3" }, // Blue & Peach
  { drawer: "#D4E157", appBar: "#0288D1" }, // Lime Green & Blue
  { drawer: "#FF7043", appBar: "#26C6DA" }, // Orange & Cyan
  { drawer: "#9C27B0", appBar: "#00BFAE" }, // Purple & Turquoise
];

const SettingsPage = () => {
  const navigate = useNavigate();
  const overlayRef = useRef(null); // To detect clicks outside the color picker
  const [colors, setColors] = useState({
    drawerBackground: localStorage.getItem("drawerBackground") || "#FF7043",
    appBarBackground: localStorage.getItem("appBarBackground") || "#26C6DA",
  });

  const [isDrawerColorPickerVisible, setDrawerColorPickerVisible] =
    useState(false);
  const [isAppBarColorPickerVisible, setAppBarColorPickerVisible] =
    useState(false);

  useEffect(() => {
    // Close color picker if clicked outside
    const handleClickOutside = (event) => {
      if (overlayRef.current && !overlayRef.current.contains(event.target)) {
        setDrawerColorPickerVisible(false);
        setAppBarColorPickerVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleColorChange = (color, field) => {
    setColors((prev) => ({
      ...prev,
      [field]: color.hex,
    }));
  };

  const saveSettings = () => {
    localStorage.setItem("drawerBackground", colors.drawerBackground);
    localStorage.setItem("appBarBackground", colors.appBarBackground);
    navigate("/"); // Redirect after saving
  };

  const resetColors = () => {
    const defaultColors = {
      drawerBackground: "#FF7043", // Deep Blue
      appBarBackground: "#26C6DA", // Light Blue
    };
    setColors(defaultColors);
    localStorage.setItem("drawerBackground", defaultColors.drawerBackground);
    localStorage.setItem("appBarBackground", defaultColors.appBarBackground);
  };

  return (
    <Layout>
      <Box
        sx={{
          p: 3,
          maxHeight: "80vh",
          overflowY: "auto", // Enable scroll if content overflows
          "&::-webkit-scrollbar": {
            width: "2px", // Thin scrollbar
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: colors.appBarBackground, // AppBar color
            borderRadius: "10px",
          },
        }}
      >
        <Typography variant="h4">Settings</Typography>

        {/* Color Combination Section */}
        <Box sx={{ mt: 3 }}>
          <Typography variant="h6">Choose a Color Combination</Typography>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            {colorCombinations.map((combination, index) => (
              <Grid item key={index}>
                <Paper
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: 1,
                    cursor: "pointer",
                    borderRadius: 1,
                    boxShadow: 2,
                    "&:hover": {
                      boxShadow: 6,
                    },
                  }}
                  onClick={() => {
                    setColors({
                      drawerBackground: combination.drawer,
                      appBarBackground: combination.appBar,
                    });
                  }}
                >
                  <Box
                    sx={{
                      width: 40,
                      height: 40,
                      backgroundColor: combination.drawer,
                      borderRadius: "0%",
                      marginBottom: 0,
                    }}
                  />
                  <Box
                    sx={{
                      width: 40,
                      height: 40,
                      backgroundColor: combination.appBar,
                      borderRadius: "0%",
                    }}
                  />
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Custom Color Picker Section */}
        <Box sx={{ mt: 3 }}>
          <Typography variant="h6">Or Select Custom Colors</Typography>

          {/* Drawer Color Picker */}
          <Box sx={{ mt: 2 }}>
            <Typography variant="body1">Drawer Background Color</Typography>
            <Button
              variant="outlined"
              onClick={() =>
                setDrawerColorPickerVisible(!isDrawerColorPickerVisible)
              }
            >
              Select Custom Drawer Color
            </Button>

            {isDrawerColorPickerVisible && (
              <div ref={overlayRef}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <ChromePicker
                    color={colors.drawerBackground}
                    onChangeComplete={(color) =>
                      handleColorChange(color, "drawerBackground")
                    }
                    disableAlpha
                    sx={{ mt: 2 }}
                  />
                  <Button
                    variant="outlined"
                    onClick={() => setDrawerColorPickerVisible(false)}
                    sx={{
                      ml: 1,
                      p: 1,
                      minWidth: "auto",
                      borderRadius: "50%",
                      backgroundColor: "transparent",
                    }}
                  >
                    <CloseIcon />
                  </Button>
                </Box>
              </div>
            )}
          </Box>

          {/* App Bar Color Picker */}
          <Box sx={{ mt: 3 }}>
            <Typography variant="body1">App Bar Background Color</Typography>
            <Button
              variant="outlined"
              onClick={() =>
                setAppBarColorPickerVisible(!isAppBarColorPickerVisible)
              }
            >
              Select Custom App Bar Color
            </Button>

            {isAppBarColorPickerVisible && (
              <div ref={overlayRef}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <ChromePicker
                    color={colors.appBarBackground}
                    onChangeComplete={(color) =>
                      handleColorChange(color, "appBarBackground")
                    }
                    disableAlpha
                    sx={{ mt: 2 }}
                  />
                  <Button
                    variant="outlined"
                    onClick={() => setAppBarColorPickerVisible(false)}
                    sx={{
                      ml: 1,
                      p: 1,
                      minWidth: "auto",
                      borderRadius: "50%",
                      backgroundColor: "transparent",
                    }}
                  >
                    <CloseIcon />
                  </Button>
                </Box>
              </div>
            )}
          </Box>
        </Box>

        {/* Selected Colors Display */}
        <Box sx={{ mt: 3 }}>
          <Typography variant="h6">Selected Colors</Typography>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Box
              sx={{
                width: 40,
                height: 40,
                backgroundColor: colors.drawerBackground,
                borderRadius: 1,
                boxShadow: 2,
              }}
            />
            <Box
              sx={{
                width: 40,
                height: 40,
                backgroundColor: colors.appBarBackground,
                borderRadius: 1,
                boxShadow: 2,
              }}
            />
          </Box>
        </Box>

        {/* Action Buttons */}
        <Grid container spacing={2} sx={{ mt: 3 }}>
          <Grid item>
            <Button
              variant="outlined"
              color="secondary"
              sx={{ mr: 2 }}
              onClick={resetColors}
            >
              Reset to Default
            </Button>
          </Grid>

          <Grid item>
            <Button variant="contained" color="primary" onClick={saveSettings}>
              Save Settings
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
};

export default SettingsPage;
