import React, { useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Container,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Layout from "../../utils/Layout";
import config from "../../config";
import { useToast } from "../../utils/ToastContext";

const AddQuote = () => {
  const [formData, setFormData] = useState({
    ref_no: "",
    quote_name: "",
    quote_date: "",
    quoted_amount: "",
    description: "",
    submitted_to: "",
    status: "Pending",
    username: localStorage.username,
    session_id: localStorage.session_id,
  });

  const navigate = useNavigate();
  const triggerToast = useToast();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Check for mobile screens

  const handleChange = (e) => {
    const { name, value } = e.target;

    if ((name === "ref_no" || name === "quote_name") && value.includes("/")) {
      triggerToast(
        "error",
        "Reference Number and Quote Name cannot contain '/'",
      );
      return;
    }

    if (name === "quote_date") {
      const year = value.split("-")[0];
      if (year.length > 4) {
        triggerToast("error", "Year cannot have more than 4 digits.");
        return;
      }
      if (!/^[2-9]\d{3}$/.test(year)) {
        triggerToast(
          "error",
          "Year must be a four-digit number and cannot start with 0 or 1.",
        );
        return;
      }
    }

    if (name === "quoted_amount" && !/^\d*$/.test(value)) {
      triggerToast("error", "Quoted Amount must contain only numbers.");
      return;
    }

    if (name === "submitted_to") {
      if (!/^[A-Za-z\s]+$/.test(value)) {
        triggerToast("error", "Submitted To must contain only letters.");
        return;
      }
      const formattedValue = value.replace(/\b\w/g, (char) =>
        char.toUpperCase(),
      );
      setFormData({ ...formData, [name]: formattedValue });
      return;
    }

    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log("Data sent:", formData);
      const response = await axios.post(
        `${config.apiBaseUrl}/quote/add_quote.php`,
        formData,
        { headers: { "Content-Type": "application/json" } },
      );

      if (response.data.success) {
        triggerToast("success", response.data.message);
        navigate("/quotes");
      } else {
        triggerToast("error", response.data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      triggerToast("error", "Something went wrong!");
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Layout>
      <Container maxWidth="sm">
        {!isMobile && ( // Hide on mobile
          <Box display="flex" alignItems="center" mb={2}>
            <IconButton onClick={handleBack}>
              <ArrowBack />
            </IconButton>
            <Typography variant="h5" ml={1}>
              Add New Quote
            </Typography>
          </Box>
        )}
        <form onSubmit={handleSubmit}>
          <Box mb={2}>
            <TextField
              label="Reference Number"
              variant="outlined"
              fullWidth
              name="ref_no"
              value={formData.ref_no}
              onChange={handleChange}
              required
            />
          </Box>
          <Box mb={2}>
            <TextField
              label="Quote Name"
              variant="outlined"
              fullWidth
              name="quote_name"
              value={formData.quote_name}
              onChange={handleChange}
              required
            />
          </Box>
          <Box mb={2}>
            <TextField
              label="Quote Date"
              type="date"
              variant="outlined"
              fullWidth
              name="quote_date"
              value={formData.quote_date}
              onChange={handleChange}
              required
              InputLabelProps={{ shrink: true }}
            />
          </Box>
          <Box mb={2}>
            <TextField
              label="Quoted Amount"
              variant="outlined"
              fullWidth
              name="quoted_amount"
              value={formData.quoted_amount}
              onChange={handleChange}
              required
            />
          </Box>
          <Box mb={2}>
            <TextField
              label="Description"
              variant="outlined"
              multiline
              rows={4}
              fullWidth
              name="description"
              value={formData.description}
              onChange={handleChange}
            />
          </Box>
          <Box mb={2}>
            <TextField
              label="Submitted To"
              variant="outlined"
              fullWidth
              name="submitted_to"
              value={formData.submitted_to}
              onChange={handleChange}
            />
          </Box>
          <Box mt={2}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Add Quote
            </Button>
          </Box>
        </form>
      </Container>
    </Layout>
  );
};

export default AddQuote;
