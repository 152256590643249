import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Container,
  MenuItem,
  IconButton,
  useMediaQuery,
  useTheme,
  CircularProgress,
  Fab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Layout from "./Layout";
import config from "../config";
import { useToast } from "./ToastContext";

import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";

const months = [
  { value: "1", label: "January" },
  { value: "2", label: "February" },
  { value: "3", label: "March" },
  { value: "4", label: "April" },
  { value: "5", label: "May" },
  { value: "6", label: "June" },
  { value: "7", label: "July" },
  { value: "8", label: "August" },
  { value: "9", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
];

const AddBulkAttendance = () => {
  const [formData, setFormData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [attendanceIdToDelete, setAttendanceIdToDelete] = useState(null);

  const [loading, setLoading] = useState(false);
  const [loadingAttendance, setLoadingAttendance] = useState(false);
  const [siteList, setSiteList] = useState([]);
  const [empList, setEmpList] = useState([]);
  const [attendanceData, setAttendanceData] = useState([]);
  const [selectedSite, setSelectedSite] = useState("");
  const [selectedYear, setSelectedYear] = useState(
    sessionStorage.getItem('selectedYear') || new Date().getFullYear()
  );
  
  const [selectedMonth, setSelectedMonth] = useState(
    sessionStorage.getItem('selectedMonth') || (new Date().getMonth() + 1)
  );
  
  const triggerToast = useToast();
  const theme = useTheme();

  const [searchQuery, setSearchQuery] = useState("");

  const currentDate = new Date();
  const currentMonth = (currentDate.getMonth() + 1).toString();
  const currentYear = currentDate.getFullYear().toString();

  const [month, setMonth] = useState(currentMonth);
  const [year, setYear] = useState(currentYear);

  const handlePrevMonth = () => {
    let prevMonth = parseInt(selectedMonth) - 1;
    let prevYear = parseInt(selectedYear);
  
    if (prevMonth < 1) {
      prevMonth = 12; 
      prevYear -= 1; 
    }
  
    setSelectedMonth(prevMonth.toString());
    setSelectedYear(prevYear.toString()); // Ensure selectedYear updates
    setMonth(prevMonth.toString()); // Update month as well
    setYear(prevYear.toString()); // Update year as well
  };
  
  const handleNextMonth = () => {
    let nextMonth = parseInt(selectedMonth) + 1;
    let nextYear = parseInt(selectedYear);
  
    if (nextMonth > 12) {
      nextMonth = 1; // Reset to January
      nextYear += 1; // Move to next year
    }
  
    setSelectedMonth(nextMonth.toString());
    setSelectedYear(nextYear.toString()); // Ensure selectedYear updates
    setMonth(nextMonth.toString()); // Update month as well
    setYear(nextYear.toString()); // Update year as well
  };
  

  useEffect(() => {
    // Fetch stored values from sessionStorage
    const storedMonth = sessionStorage.getItem("selectedMonth");
    const storedYear = sessionStorage.getItem("selectedYear");
    const storedSite = sessionStorage.getItem("selectedSite");
  
    console.log("Fetched from sessionStorage:");
    console.log("Stored Month:", storedMonth);
    console.log("Stored Year:", storedYear);
    console.log("Stored Site:", storedSite);
  
    if (storedMonth) {
      setSelectedMonth(storedMonth);
    }
    if (storedYear) {
      setSelectedYear(storedYear);
    }
    if (storedSite) {
      setSelectedSite(storedSite);
    }
  }, []);
  
  useEffect(() => {
    // Store values in sessionStorage whenever they change
    console.log("Saving to sessionStorage:");
    console.log("Selected Month:", selectedMonth);
    console.log("Selected Year:", selectedYear);
    console.log("Selected Site:", selectedSite);
  
    sessionStorage.setItem("selectedMonth", selectedMonth);
    sessionStorage.setItem("selectedYear", selectedYear);
    sessionStorage.setItem("selectedSite", selectedSite);
  }, [selectedMonth, selectedYear, selectedSite]);
  

  const handleChange = (e, index) => {
    const { name, value } = e.target;
  
    // Regular expression to allow only numbers (positive integers or decimals)
    const numberRegex = /^[0-9]*\.?[0-9]*$/;
  
    if (name === "present" || name === "mess" || name === "advance" || name === "wifi" || name === "visa") {
      // If the value doesn't match the regex, return early and don't update the state
      if (!numberRegex.test(value)) {
        return;
      }
    }
  
    setFormData((prevData) => {
      const updatedData = [...prevData];
      const updatedRow = { ...updatedData[index], [name]: value };
  
      // Auto-calculate deduction (total_reduction)
      const mess = parseFloat(updatedRow.mess) || 0;
      const advance = parseFloat(updatedRow.advance) || 0;
      const wifi = parseFloat(updatedRow.wifi) || 0;
      const visa = parseFloat(updatedRow.visa) || 0;
      updatedRow.total_reduction = (mess + advance + wifi + visa).toFixed(3);
  
      updatedData[index] = updatedRow;
      return updatedData;
    });
  };
  

  const getLastDayOfMonth = (year, month) => {
    const date = new Date(year, month, 0);
    const day = date.getDate();
    return `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(2, "0")}`;
  };

  useEffect(() => {
    const fetchAttendanceData = async () => {
      if (!selectedSite || !selectedYear || !selectedMonth) return;
      setLoadingAttendance(true);
      try {
        const response = await axios.post(
          `${config.apiBaseUrl}/attendance/get_listby_site_date.php`,
          {
            username: localStorage.username,
            session_id: localStorage.session_id,
            site_id: selectedSite,
            month: selectedMonth,
            year: selectedYear,
            search: searchQuery,
          },
          { headers: { "Content-Type": "application/json" } },
        );

        if (response.data.success) {
          const attendanceRecords = response.data.data;
          // console.log("Fetched Attendance Data:", attendanceRecords);
          setAttendanceData(attendanceRecords);
        } else {
          triggerToast("error", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching attendance records:", error);
        triggerToast("error", "Failed to fetch attendance records.");
      }
      setLoadingAttendance(false);
    };

    fetchAttendanceData();
  }, [selectedSite, selectedYear, selectedMonth, searchQuery]); // Re-fetch when these change

  useEffect(() => {
    const fetchSiteNames = async () => {
      // Check if site list exists in sessionStorage
      const cachedSites = sessionStorage.getItem("siteList");

      if (cachedSites) {
        console.log("Using cached site list");
        setSiteList(JSON.parse(cachedSites));
        return;
      }

      console.log("Fetching site list from API");
      try {
        const response = await axios.post(
          `${config.apiBaseUrl}/site/get_site_list.php`,
          {
            username: localStorage.username,
            session_id: localStorage.session_id,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          },
        );

        if (response.data.success) {
          setSiteList(response.data.data);
          sessionStorage.setItem(
            "siteList",
            JSON.stringify(response.data.data),
          );
        } else {
          triggerToast("error", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching site names:", error);
        triggerToast("error", "Failed to load site names.");
      }
    };

    fetchSiteNames();
  }, []);

  useEffect(() => {
    if (selectedSite && selectedYear && selectedMonth) {
      const cacheKey = `empList_${selectedSite}`; // Unique key per site
      const cachedEmpList = sessionStorage.getItem(cacheKey);

      if (cachedEmpList) {
        console.log(`Using cached employee list for site ${selectedSite}`);
        const parsedEmpList = JSON.parse(cachedEmpList);
        setEmpList(parsedEmpList);

        const lastDay = getLastDayOfMonth(selectedYear, selectedMonth);
        setFormData(
          parsedEmpList.map((emp) => ({
            siteId: selectedSite,
            empId: emp.id,
            date: lastDay,
            session_id: localStorage.session_id,
            username: localStorage.username,
            present: "",
            mess: "",
            wifi: "",
            advance: "",
            visa: "",
            total_reduction: "",
            visa_remarks: "",
          })),
        );
        return;
      }

      console.log(`Fetching employee list for site ${selectedSite} from API`);
      const fetchEmpNames = async () => {
        try {
          const response = await axios.post(
            `${config.apiBaseUrl}/employee/get_emp_list_nick.php`,
            {
              username: localStorage.username,
              session_id: localStorage.session_id,
              siteId: selectedSite,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            },
          );

          if (response.data.success) {
            setEmpList(response.data.data);
            sessionStorage.setItem(
              cacheKey,
              JSON.stringify(response.data.data),
            );

            const lastDay = getLastDayOfMonth(selectedYear, selectedMonth);
            setFormData(
              response.data.data.map((emp) => ({
                siteId: selectedSite,
                empId: emp.id,
                date: lastDay,
                session_id: localStorage.session_id,
                username: localStorage.username,
                present: "",
                mess: "",
                wifi: "",
                advance: "",
                visa: "",
                total_reduction: "",
                visa_remarks: "",
              })),
            );
          } else {
            triggerToast("error", response.data.message);
          }
        } catch (error) {
          console.error("Error fetching employees:", error);
          triggerToast("error", "Failed to load employees.");
        }
      };

      fetchEmpNames();
    }
  }, [selectedSite, selectedYear, selectedMonth]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) return;
    setLoading(true);
    try {
      const nonEmptyData = formData.filter(
        (row) => row.present !== "" || row.mess !== "",
      );

      if (nonEmptyData.length === 0) {
        triggerToast("error", "No valid data to submit.");
        return;
      }
      console.log("Sending bulk data to backend:", nonEmptyData);
      const response = await axios.post(
        `${config.apiBaseUrl}/attendance/add_attendance_bulk.php`,
        nonEmptyData, // Sending array instead of looping
        {
          headers: { "Content-Type": "application/json" },
        },
      );

      if (response.data.success) {
        response.data.results.forEach((result) => {
          triggerToast(
            result.status === "added" ? "success" : "info",
            result.message,
          );
        });
      } else {
        triggerToast("error", `Error: ${response.data.message}`);
      }
    } catch (error) {
      console.error("Error submitting attendance:", error);
      triggerToast("error", "An unexpected error occurred.");
    } finally {
      setLoading(false);
    }
  };

  const handleClearRow = (index) => {
    setFormData((prevFormData) => {
      const updatedData = [...prevFormData];
      updatedData[index] = {
        present: "",
        mess: "",
        wifi: "",
        advance: "",
        visa: "",
        total_reduction: "",
        visa_remarks: "",
      };
      return updatedData;
    });
  };

  const handleDeleteConfirmation = (attendanceId) => {
    setAttendanceIdToDelete(attendanceId); // Set the attendance ID for deletion
    setOpenDialog(true); // Open the confirmation dialog
  };

  const handleCloseDialog = () => {
    setOpenDialog(false); // Close the dialog without deleting
  };

  const handleConfirmDelete = () => {
    deleteAttendance(attendanceIdToDelete); // Call the function to delete the attendance
    setOpenDialog(false);
  };

  const deleteAttendance = async (attendanceId) => {
    try {
      const requestBody = {
        username: localStorage.username,
        session_id: localStorage.session_id,
        id: attendanceId,
      };

      const response = await axios.post(
        `${config.apiBaseUrl}/attendance/delete_attendance.php`,
        requestBody,
        { headers: { "Content-Type": "application/json" } },
      );

      if (response.data.success) {
        triggerToast(
          "success",
          "Attendance record deleted successfully.",
          "#4caf50",
        );
        setAttendanceData((prevData) =>
          prevData.filter((item) => item.id !== attendanceId),
        );
      } else {
        triggerToast("warning", response.data.message, "#ff9800");
      }
    } catch (error) {
      console.error("Error deleting attendance record:", error);
      triggerToast("error", "Failed to delete attendance record.", "#f44336");
    }
  };

  return (
    <Layout>
      <Container maxWidth="auto">
        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              maxWidth: "auto",
              margin: "auto",
              overflow: "hidden",
              minHeight: "80vh",
            }}
          >
            <Box
              sx={{
                width: "100%",
                position: "sticky",
                top: 0,
                zIndex: 2,
                backgroundColor: "white",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                display: "flex",
                justifyContent: "Center",
                alignItems: "center",
                paddingRight: 20,
                paddingLeft: 20,
                paddingBottom: 1,
                paddingTop: 1,
                gap: 2,
              }}
            >
              <TextField
                select
                label="Select Site"
                variant="outlined"
                value={selectedSite}
                onChange={(e) => setSelectedSite(e.target.value)}
                required
                size="small"
                sx={{ width: 200 }}
              >
                {siteList.map((site) => (
                  <MenuItem key={site.id} value={site.id}>
                    {site.site_name}
                  </MenuItem>
                ))}
              </TextField>
              {/* Previous Month Button */}
              <Fab
                color="primary"
                aria-label="previous"
                onClick={handlePrevMonth}
                sx={{
                  borderRadius: 1,
                  width: 35,
                  height: 35,
                  minHeight: "unset",
                }}
              >
                <ArrowBackIcon />
              </Fab>

              <TextField
  variant="outlined"
  select
  label="Month"
  value={selectedMonth || month}  // Use session data or fallback to `month` if none
  onChange={(e) => {
    const newMonth = e.target.value;
    setMonth(newMonth);
    setSelectedMonth(newMonth); // Ensure both are updated
  }}
  size="small"
  sx={{ width: 150 }}
>
  {months.map((month) => (
    <MenuItem key={month.value} value={month.value}>
      {month.label}
    </MenuItem>
  ))}
</TextField>

<TextField
  variant="outlined"
  select
  label="Year"
  value={selectedYear || year}  // Use session data or fallback to `year` if none
  onChange={(e) => {
    setYear(e.target.value);
    setSelectedYear(e.target.value); // Update selectedYear to trigger the effect
  }}
  size="small"
  sx={{ width: 100 }}
>
  {[...Array(4)].map((_, index) => {
    const yearOption = (parseInt(currentYear) - index).toString();
    return (
      <MenuItem key={yearOption} value={yearOption}>
        {yearOption}
      </MenuItem>
    );
  })}
</TextField>

              <Fab
                color="primary"
                aria-label="next"
                onClick={handleNextMonth}
                sx={{
                  borderRadius: 1,
                  width: 35,
                  height: 35,
                  minHeight: "unset",
                }}
              >
                <ArrowForwardIcon />
              </Fab>
            </Box>

            {loading ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="60vh"
              >
                <Typography variant="h6" color="primary">
                  Loading...
                </Typography>
              </Box>
            ) : selectedSite === "" ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="60vh"
              >
                <Typography variant="h6" color="error">
                  Select a site to view data
                </Typography>
              </Box>
            ) : loadingAttendance ? (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                minHeight="50vh"
              >
                <CircularProgress />
                <Typography sx={{ marginLeft: 2 }}>
                      Loading data for {selectedMonth},{selectedYear}, { selectedSite}
                </Typography>
              </Box>
            ) : (
              <TableContainer
                component={Paper}
                sx={{
                  minHeight: "50vh",
                  overflow: "auto",
                  "&::-webkit-scrollbar": {
                    width: "2px",
                    height: "2px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: localStorage.appBarBackground || "#888",
                    borderRadius: "2px",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "transparent",
                  },
                }}
              >
                <Table stickyHeader sx={{ borderCollapse: "collapse" }}>
                  <TableHead>
                    <TableRow sx={{ height: 20, backgroundColor: "#f5f5f5" }}>
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          position: "sticky",
                          left: 0,
                          backgroundColor: "#e0e0e0",
                          zIndex: 10,
                          borderRight: "1px solid #ddd",
                          borderLeft: "1px solid #ddd",
                          borderTop: "1px solid #ddd",
                          borderBottom: "1px solid white",
                        }}
                      >
                        Employee
                      </TableCell>
                      {[
                        "Present",
                        "Mess",
                        "Advance",
                        "WiFi",
                        "Visa",
                        "Deduction",
                        "Remarks",
                      ].map((header) => (
                        <TableCell
                          key={header}
                          sx={{
                            fontWeight: "bold",
                            border: "1px solid white",
                            textAlign: "center",
                            backgroundColor: "#f5f5f5",
                            padding: 0,
                          }}
                        >
                          {header}
                        </TableCell>
                      ))}
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          border: "1px solid white",
                          textAlign: "center",
                          backgroundColor: "#f5f5f5",
                        }}
                      >
                        A
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {empList.map((emp, index) => {
                      const existingRecord = attendanceData.find(
                        (record) => record.emp_id === emp.id,
                      );

                      const hasData = [
                        "present",
                        "mess",                        
                        "advance",
                        "wifi",
                        "visa",
                        "total_reduction",
                        "visa_remarks",
                      ].some(
                        (field) =>
                          formData[index]?.[field] || existingRecord?.[field],
                      );

                      return (
                        <TableRow
                          key={emp.id}
                          sx={{
                            "&:nth-of-type(odd)": {
                              backgroundColor: "#f5f5f5",
                            },
                            "&:nth-of-type(even)": {
                              backgroundColor: "#ffffff",
                            },
                            height: 30,
                          }}
                        >
                          <TableCell
                            sx={{
                              position: "sticky",
                              left: 0,
                              backgroundColor: "#e0e0e0",
                              zIndex: 1,
                              fontWeight: "bold",
                              borderLeft: "1px solid #white",
                              borderRight: "1px solid #white",
                              borderBottom: "1px solid white",
                              padding: 1,
                              minWidth: 120,
                              boxSizing: "border-box",
                            }}
                          >
                            {emp.nickname}
                          </TableCell>

                          {[
                            "present",
                            "mess",                           
                            "advance",
                            "wifi",
                            "visa",
                            "total_reduction",
                            "visa_remarks",
                          ].map((field) => (
                            <TableCell
                              key={field}
                              sx={{
                                minWidth: 70,
                                textAlign: "center",
                                padding: 0,
                              }}
                            >
                              <TextField
                                name={field}
                                value={
                                  formData[index]?.[field] ||
                                  existingRecord?.[field] ||
                                  ""
                                }
                                onChange={(e) => handleChange(e, index)}
                                fullWidth
                                variant="outlined"
                                size="small"
                                InputProps={{
                                  sx: {
                                    padding: 0,
                                    fontSize: 14,
                                    textAlign: "center",
                                    height: 35,
                                  },
                                }}
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    backgroundColor: "transparent",
                                    borderRadius: 0,
                                    "& fieldset": { borderRadius: 0 },
                                  },
                                }}
                              />
                            </TableCell>
                          ))}

                          <TableCell
                            sx={{
                              textAlign: "center",
                              minWidth: 10,
                              padding: "4px",
                              border: "px solid white",
                            }}
                          >
                            {hasData && (
                              <IconButton
                                color="error"
                                size="small"
                                onClick={() =>
                                  existingRecord
                                    ? handleDeleteConfirmation(
                                        existingRecord.id,
                                      )
                                    : handleClearRow(index)
                                }
                              >
                                {existingRecord ? (
                                  <DeleteIcon />
                                ) : (
                                  <ClearIcon />
                                )}
                              </IconButton>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}

            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
            >
              {loading ? "Saving..." : "Save"}
            </Button>
          </Box>
        </form>
      </Container>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this attendance record? This action
          cannot be undone.
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
};

export default AddBulkAttendance;
