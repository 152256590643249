import React, { useEffect } from "react";
import { Card, CardActionArea, Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Layout from "../utils/Layout";
import routes from "../routes";

const Dashboard = () => {
  const navigate = useNavigate();
  const mainPages = routes.filter((route) => route.showInDashboard);

  useEffect(() => {
    document.body.style.overflow = "hidden"; // Prevent scrolling

    return () => {
      document.body.style.overflow = "auto"; // Restore scrolling when leaving the page
    };
  }, []);

  return (
    <Layout>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(80px, 1fr))",
          gap: 2, // Reduced from 16 to 10 to decrease spacing
          padding: 0,
          justifyContent: "center",
          alignContent: "center",
          minHeight: "50vh",
          maxWidth: "400px",
          margin: "0 auto",
        }}
      >
        {mainPages.map((page) => (
          <Box
            key={page.path}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CardActionArea onClick={() => navigate(page.path)}>
              <Card
                sx={{
                  width: 80,
                  height: 80,
                  borderRadius: "20px",
                  // backgroundColor: localStorage.drawerBackground,
                  boxShadow: 3,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  transition: "transform 0.2s ease-in-out",
                  "&:hover": {
                    transform: "scale(1.1)",
                    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.3)",
                  },
                }}
              >
                <Box
                  sx={{
                    width: "80%",
                    height: "80%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={page.image}
                    alt={page.title}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </Box>
              </Card>
            </CardActionArea>
            <Typography
              variant="caption"
              sx={{
                marginTop: 0.5, // Reduced from 1 to 0.5 for tighter spacing
                fontSize: "12px",
                textAlign: "center",
                color: "#333",
              }}
            >
              {page.title}
            </Typography>
          </Box>
        ))}
      </Box>
    </Layout>
  );
};

export default Dashboard;
