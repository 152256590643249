import React, { useState, useEffect, useMemo } from "react";
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  TableSortLabel,
  Fab,
  Checkbox,
  MenuItem,
  Popper,
  ClickAwayListener,
  Paper,
  Grow,
  Tooltip,
} from "@mui/material";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useNavigate } from "react-router-dom";
import Icons from "../../utils/icon";
import config from "../../config";
import Layout from "../../utils/Layout";
import { formatDate } from "../../utils/format";
import LoadingPage from "../../utils/loading";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const SiteTable = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [data, setData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const username = localStorage.getItem("username");
      const session_id = localStorage.getItem("session_id");

      if (username && session_id) {
        try {
          setLoading(true);
          const response = await fetch(
            `${config.apiBaseUrl}/site/get_all_site.php`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                username,
                session_id,
                search: searchQuery || null,
              }),
            }
          );

          const result = await response.json();
          if (result.success) {
            setData(result.data);
          } else {
            console.error("Error fetching Site:", result.message);
          }
        } catch (error) {
          console.error("Error fetching Site:", error);
        } finally {
          setLoading(false);
        }
      } else {
        navigate("/");
      }
    };

    fetchData();
  }, [searchQuery]);

  const handleSort = (key) => {
    setSortConfig((prev) => ({
      key,
      direction: prev.key === key && prev.direction === "asc" ? "desc" : "asc",
    }));
  };

  const sortedData = useMemo(() => {
    return [...data].sort((a, b) => {
      const aValue = a[sortConfig.key] || "";
      const bValue = b[sortConfig.key] || "";
      if (aValue < bValue) return sortConfig.direction === "asc" ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === "asc" ? 1 : -1;
      return 0;
    });
  }, [data, sortConfig]);

  const handleCheckboxChange = (id) => {
    setSelectedData((prev) =>
      prev.includes(id) ? prev.filter((dataId) => dataId !== id) : [...prev, id]
    );
  };

  const handleExportPDF = () => {
    const doc = new jsPDF();
    const tableData = selectedData.map((id) => {
      const data = data.find((data) => data.id === id);
      return [
        data.given_name + " " + data.surname,
        data.dob,
        data.id_number,
        data.id_expiry_date,
        data.passport_expiry_date,
      ];
    });

    doc.text("Site Report", 14, 10);
    doc.autoTable({
      head: [
        [
          "Site Name",
          "Client Details",
          "Start Date",
          "Client Details",
          "Remarks",
        ],
      ],
      body: tableData,
    });

    doc.save("Site_List.pdf");
  };

  const handleFilterClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const theme = createTheme({
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: "4px",
          },
        },
      },
    },
  });

  const cellStyles = {
    border: "none",
    boxShadow: "0px 0px 0px 0.5px rgba(127, 147, 184, 0.2)",
  };

  const getDateColor = (date) => {
    if (!date || date === "0000-00-00") {
      return { color: "grey", backgroundColor: "transparent" }; // Default style for invalid dates
    }

    const today = new Date();
    const parsedDate = new Date(date.replace(/-/g, "/"));

    if (isNaN(parsedDate)) {
      return { color: "grey", backgroundColor: "transparent" }; // Fallback for invalid date
    }

    const diffInDays = Math.ceil((parsedDate - today) / (1000 * 60 * 60 * 24));

    if (diffInDays <= 0) {
      return { color: "white", backgroundColor: "red" }; // Expired or today
    } else if (diffInDays <= 7) {
      return { color: "black", backgroundColor: "orange" }; // Within a week
    } else if (diffInDays <= 30) {
      return { color: "black", backgroundColor: "yellow" }; // Within a month
    } else if (diffInDays <= 90) {
      return { color: "black", backgroundColor: "lightgreen" }; // Within 3 months
    }

    return { color: "grey", backgroundColor: "transparent" }; // Default for dates beyond a month
  };

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          height: "85vh",
          maxWidth: "lg",
          margin: "auto",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            width: "100%",
            position: "sticky",
            top: 0,
            zIndex: 2,
            backgroundColor: "white",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            display: "flex",
            justifyContent: "Center",
            alignItems: "center",
            paddingRight: 20,
            paddingLeft: 20,
            paddingBottom: 1,
            gap: 2,
          }}
        >
          <Fab
            color="primary"
            aria-label="add"
            onClick={handleExportPDF}
            disabled={selectedData.length === 0}
            sx={{
              borderRadius: 1,
              width: 35,
              height: 35,
              minHeight: "unset",
            }}
          >
            <Icons.Download />
          </Fab>
          <TextField
            variant="outlined"
            placeholder="Search Site..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            size="small"
            sx={{ flexGrow: 0.5, marginLeft: 1 }}
          />
          <Fab
            color="secondary"
            aria-label="filter"
            onClick={handleFilterClick}
            sx={{
              marginRight: 1,
              borderRadius: 1,
              width: 35,
              height: 35,
              minHeight: "unset",
              zIndex: 100,
            }}
          >
            <Icons.FilterListIcon />
          </Fab>
          <Fab
            color="primary"
            aria-label="add"
            onClick={() => navigate("/add_site")}
            sx={{
              borderRadius: 1,
              width: 35,
              height: 35,
              minHeight: "unset",
            }}
          >
            <Icons.Add />
          </Fab>
        </Box>

        {/* Table Container */}
        <ThemeProvider theme={theme}>
          <TableContainer
            sx={{
              flexGrow: 1,
              height: "calc(100vh - 90px)",
              overflow: "auto",
              "&::-webkit-scrollbar": {
                width: "5px",
                height: "5px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                borderRadius: "10px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.7)",
              },
            }}
          >
            <Table
              stickyHeader
              sx={{
                tableLayout: "auto",
                width: "lg",
              }}
            >
              {" "}
              <TableHead>
                <TableRow
                  sx={{
                    bgcolor: "primary.main",
                    color: "white",
                    zIndex: 1000,
                  }}
                >
                  {" "}
                  <TableCell padding="checkbox">
                    <Checkbox
                      indeterminate={
                        selectedData.length > 0 &&
                        selectedData.length < sortedData.length
                      }
                      checked={
                        selectedData.length === sortedData.length &&
                        sortedData.length > 0
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedData(sortedData.map((data) => data.id)); // Select all
                        } else {
                          setSelectedData([]); // Deselect all
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortConfig.key === "name"}
                      direction={sortConfig.direction}
                      onClick={() => handleSort("name")}
                    >
                      id
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Site Name</TableCell>
                  <TableCell>Client Name</TableCell>
                  <TableCell>Client Number</TableCell>
                  <TableCell>Start Date</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              {loading ? (
                <div className="loading-container">
                  <div className="spinner"></div>
                  <p>Loading, please wait...</p>
                </div>
              ) : (
                <TableBody>
                  {sortedData.map((data) => (
                    <TableRow
                      key={data.id}
                      sx={{
                        "&:nth-of-type(odd)": { bgcolor: "grey.200" }, // Alternate row colors
                      }}
                    >
                      <TableCell>
                        <Checkbox
                          checked={selectedData.includes(data.id)}
                          onChange={() => handleCheckboxChange(data.id)}
                        />
                      </TableCell>
                      <TableCell sx={cellStyles}>{data.id}</TableCell>
                      <TableCell
                        sx={cellStyles}
                        onClick={() => navigate(`/site_details/${data.id}`)}
                      >
                        <Box
                          sx={{
                            cursor: "pointer",
                            color: "blue", // Link color
                            textDecoration: "underline", // Underline the text
                            "&:hover": {
                              color: "darkblue", // Darker color on hover
                              textDecoration: "none", // Remove underline on hover
                            },
                          }}
                        >
                          {data.site_name}
                        </Box>
                      </TableCell>
                      <TableCell sx={cellStyles}>{data.client_name}</TableCell>
                      <TableCell sx={cellStyles}>
                        {data.client_number}
                      </TableCell>
                      <TableCell sx={cellStyles}>
                        {formatDate(data.start_date)}
                      </TableCell>

                      <TableCell sx={cellStyles}>
                        <Fab
                          color="secondary"
                          aria-label="upload"
                          onClick={() => navigate(`/site_files/${data.id}`)}
                          sx={{
                            width: 35,
                            height: 35,
                            borderRadius: "4px",
                            minHeight: "unset",
                            minWidth: "unset",
                            marginRight: "8px", // Add margin to the right

                            zIndex: 1,
                          }}
                        >
                          <Icons.UploadFileIcon sx={{ fontSize: 20 }} />
                        </Fab>

                        <Fab
                          color="primary"
                          aria-label="edit"
                          onClick={() => navigate(`/edit_site/${data.id}`)}
                          sx={{
                            width: 35,
                            height: 35,
                            borderRadius: "4px",
                            minHeight: "unset",
                            minWidth: "unset",
                            zIndex: 1,
                          }}
                        >
                          <Icons.EditNoteIcon sx={{ fontSize: 20 }} />
                        </Fab>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </ThemeProvider>
      </Box>
    </Layout>
  );
};

export default SiteTable;
