import React, { useState } from "react";
import {
  Button,
  TextField,
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  IconButton,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import { useNavigate, useParams } from "react-router-dom";
import config from "../../config";
import { useToast } from "../../utils/ToastContext";
import Layout from "../../utils/Layout";
import PdfIcon from "@mui/icons-material/PictureAsPdf"; // Import PDF icon
import DeleteIcon from "@mui/icons-material/Delete"; // Import Delete icon

const FileUploadPage = () => {
  const { fileid } = useParams();
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); // For navigation

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => handleFileDrop(acceptedFiles),
    multiple: true,
    accept: "application/*",
  });

  const triggerToast = useToast();

  const handleFileDrop = (acceptedFiles) => {
    const newFiles = acceptedFiles.map((file) => ({
      file,
      filename: file.name,
      remark: "", // Initialize empty remark field
    }));
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleFilenameChange = (index, newFilename) => {
    const updatedFiles = [...files];
    updatedFiles[index].filename = newFilename;
    setFiles(updatedFiles);
  };

  const handleRemarkChange = (index, newRemark) => {
    const updatedFiles = [...files];
    updatedFiles[index].remark = newRemark;
    setFiles(updatedFiles);
  };

  const handleFileUpload = async () => {
    const username = localStorage.getItem("username");
    const session_id = localStorage.getItem("session_id");

    if (!username || !session_id) {
      triggerToast("error", "User is not authenticated!");
      return;
    }

    if (files.length === 0) {
      triggerToast("error", "No files selected.");
      return;
    }

     // Check if any file has an empty filename
  const invalidFile = files.some(({ filename }) => !filename.trim());
  if (invalidFile) {
    triggerToast("error", "All files must have a name.");
    return;
  }

    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("fileid", fileid);
      formData.append("username", username);
      formData.append("session_id", session_id);

      // Convert filenames & remarks into JSON strings
      const filenamesArray = files.map(({ filename }) => filename);
      const remarksArray = files.map(({ remark }) => remark);

      formData.append("filenames", JSON.stringify(filenamesArray));
      formData.append("remarks", JSON.stringify(remarksArray));

      // Append actual files
      files.forEach(({ file }) => {
        formData.append("files[]", file);
      });

      const response = await fetch(
        `${config.apiBaseUrl}/file/upload_files.php`,
        {
          method: "POST",
          body: formData,
        }
      );

      const result = await response.json();
      if (!result.success) {
        throw new Error(result.message || "File upload failed");
      }

      triggerToast("success", "Files uploaded successfully!");
      navigate(-1);
    } catch (error) {
      console.error("Error uploading files:", error);
      triggerToast("error", "Error uploading files. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleFileDelete = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  return (
    <Layout>
      <Box sx={{ padding: 3, maxHeight: "80vh", overflowY: "auto" }}>
        <Typography variant="h4" gutterBottom>
          File Upload for {fileid}
        </Typography>

        <Box
          {...getRootProps()}
          sx={{
            border: "2px dashed #ccc",
            padding: 4,
            textAlign: "center",
            cursor: "pointer",
          }}
        >
          <input {...getInputProps()} />
          <Typography variant="h6">Drag and Drop Files Here</Typography>
        </Box>

        <Grid container spacing={3} sx={{ marginTop: 3 }}>
          {files.map((fileData, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card>
                <CardContent>
                  <Typography variant="body1">{fileData.file.name}</Typography>

                  {/* File preview for images */}
                  {fileData.file.type.startsWith("image") && (
                    <CardMedia
                      component="img"
                      image={URL.createObjectURL(fileData.file)}
                      alt={fileData.file.name}
                      sx={{
                        maxHeight: 200,
                        objectFit: "contain",
                        marginBottom: 2,
                      }}
                    />
                  )}

                  {/* File preview for PDFs */}
                  {fileData.file.type === "application/pdf" && (
                    <Box sx={{ textAlign: "center", marginBottom: 2 }}>
                      <PdfIcon sx={{ fontSize: 80, color: "#f50057" }} />
                      <Typography variant="body2">PDF Document</Typography>
                    </Box>
                  )}

                  {/* Delete Button */}
                  <IconButton
                    onClick={() => handleFileDelete(index)}
                    sx={{
                      position: "absolute",
                      top: 8,
                      right: 8,
                      zIndex: 100,
                    }}
                  >
                    <DeleteIcon color="error" />
                  </IconButton>

                  {/* File name input */}
                  <TextField
                    label="File Name"
                    value={fileData.filename}
                    onChange={(e) =>
                      handleFilenameChange(index, e.target.value)
                    }
                    fullWidth
                    margin="normal"
                    error={!fileData.filename.trim()} // Show error if the filename is empty
  helperText={!fileData.filename.trim() ? "File name is required" : ""} // Error message
                  />

                  {/* Remarks input */}
                  <TextField
                    label="Remarks"
                    value={fileData.remark}
                    onChange={(e) => handleRemarkChange(index, e.target.value)}
                    fullWidth
                    margin="normal"
                  />
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 3,
          }}
        >
          {/* Cancel Button */}
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>

          {/* Upload Button */}
          <Button
            variant="contained"
            color="primary"
            onClick={handleFileUpload}
            disabled={files.length === 0 || loading}
          >
            Upload Files
          </Button>
        </Box>
      </Box>
    </Layout>
  );
};

export default FileUploadPage;
