import React, { useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Container,
  Divider,
  MenuItem,
  IconButton,
  CircularProgress,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Layout from "../../utils/Layout";
import config from "../../config";
import { useToast } from "../../utils/ToastContext";

const AddEmp = () => {
  const [formData, setFormData] = useState({
    surname: "",
    given_name: "",
    nationality: "",
    sex: "",
    dob: "",
    place_of_birth: "",
    place_of_issue: "",
    passport_number: "",
    passport_issue_date: "",
    passport_expiry_date: "",
    id_number: "",
    id_issue_date: "",
    id_expiry_date: "",
    company_name: "",
    username: localStorage.username, // Retrieve from localStorage
    session_id: localStorage.session_id, // Retrieve from localStorage
  });

  const [loading, setLoading] = useState(false); // Track loading state

  const navigate = useNavigate();
  const triggerToast = useToast();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;

    // Allow only text (alphabets and spaces) for place_of_issue and company_name, and capitalize
    if (name === "place_of_issue" || name === "company_name") {
      formattedValue = value.replace(/[^a-zA-Z\s]/g, ""); // Remove non-alphabet characters
      formattedValue = formattedValue.replace(/\b\w/g, (char) =>
        char.toUpperCase(),
      ); // Capitalize first letter of each word
    } else if (
      [
        "surname",
        "given_name",
        "nickname",
        "nationality",
        "place_of_birth",
      ].includes(name)
    ) {
      formattedValue = value.replace(/[^a-zA-Z\s]/g, ""); // Allow only alphabets and spaces
      formattedValue = formattedValue.replace(/\b\w/g, (char) =>
        char.toUpperCase(),
      ); // Capitalize first letter of each word
    } else if (name === "passport_number") {
      formattedValue = value.replace(/[^a-zA-Z0-9]/g, ""); // Remove non-alphanumeric characters
      formattedValue = formattedValue.toUpperCase(); // Capitalize all alphabets
    }
    setFormData({
      ...formData,
      [name]: formattedValue,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      console.log("Data sent:", formData);
      const response = await axios.post(
        `${config.apiBaseUrl}/employee/add_emp.php`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      console.log("Response data:", response.data);

      if (response.data.success) {
        triggerToast("success", response.data.message); // Show success message
        navigate("/emp");
      } else {
        triggerToast("error", response.data.message); // Show error message
      }
    } catch (error) {
      console.error("Error:", error);
      triggerToast("error", "Something went wrong!"); // General error
    }
  };

  // const handleBack = () => {
  //   navigate("/emp");
  // };

  const handleBack = () => {
    navigate(-1); // Navigate to the previous page
  };

  return (
    <Layout>
      <Container maxWidth="md">
        {!isMobile && ( // Hide on mobile
          <Box display="flex" alignItems="center" mb={3}>
            <IconButton onClick={handleBack}>
              <ArrowBack />
            </IconButton>
            <Typography variant="h5" ml={1}>
              Add New Employee
            </Typography>
          </Box>
        )}
        <Container
          maxWidth="md"
          style={{
            overflow: "auto",
            maxHeight: "80vh",
            scrollbarWidth: "thin",
            scrollbarColor: "lightgrey transparent",
            padding: "16px",
            border: "1px solid #e0e0e0",
            borderRadius: "8px",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            backgroundColor: "#ffffff",
          }}
        >
          <form onSubmit={handleSubmit}>
            {/* Personal Details Section */}
            <Box mb={4}>
              <Typography variant="h6" gutterBottom>
                Personal Details
              </Typography>
              <Divider />
              <Box display="flex" mt={2} mb={2} gap={1}>
                <TextField
                  label="Surname"
                  variant="outlined"
                  name="surname"
                  value={formData.surname}
                  onChange={handleChange}
                  required
                  fullWidth
                  autoCapitalize="on"
                />
                <TextField
                  label="Given Name"
                  variant="outlined"
                  name="given_name"
                  value={formData.given_name}
                  onChange={handleChange}
                  required
                  fullWidth
                  autoCapitalize="on"
                />
                <TextField
                  label="Nick Name"
                  variant="outlined"
                  name="nickname"
                  value={formData.nickname}
                  onChange={handleChange}
                  required
                  fullWidth
                  autoCapitalize="on"
                />
              </Box>

              <Box display="flex" mb={2} gap={1}>
                <TextField
                  label="Nationality"
                  variant="outlined"
                  name="nationality"
                  value={formData.nationality}
                  onChange={handleChange}
                  fullWidth
                  required
                />
                <TextField
                  label="Sex"
                  variant="outlined"
                  name="sex"
                  select
                  value={formData.sex}
                  onChange={handleChange}
                  fullWidth
                  required
                >
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                </TextField>
                <TextField
                  label="Date of Birth"
                  type="date"
                  variant="outlined"
                  name="dob"
                  value={formData.dob}
                  onChange={handleChange}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
            </Box>

            {/* Passport Details Section */}
            <Box mb={4}>
              <Typography variant="h6" gutterBottom>
                Passport Details
              </Typography>
              <Divider />
              <Box display="flex" mt={2} mb={2}>
                <TextField
                  label="Passport Number"
                  variant="outlined"
                  name="passport_number"
                  value={formData.passport_number}
                  onChange={handleChange}
                  required
                  fullWidth
                  style={{ marginRight: "8px" }}
                />
                <TextField
                  label="Place of Birth"
                  variant="outlined"
                  name="place_of_birth"
                  value={formData.place_of_birth}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Box>
              <Box display="flex" mb={2}>
                <TextField
                  label="Place of Issue"
                  variant="outlined"
                  name="place_of_issue"
                  value={formData.place_of_issue}
                  onChange={handleChange}
                  fullWidth
                  style={{ marginRight: "8px" }}
                />
                <TextField
                  label="Issue Date"
                  type="date"
                  variant="outlined"
                  name="passport_issue_date"
                  value={formData.passport_issue_date}
                  onChange={handleChange}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  style={{ marginRight: "8px" }}
                />
                <TextField
                  label="Expiry Date"
                  type="date"
                  variant="outlined"
                  name="passport_expiry_date"
                  value={formData.passport_expiry_date}
                  onChange={handleChange}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
            </Box>

            {/* Company Details Section */}
            <Box mb={4}>
              <Typography variant="h6" gutterBottom>
                Company Details
              </Typography>
              <Divider />
              <Box display="flex" mt={2}>
                <TextField
                  label="Company Name"
                  variant="outlined"
                  name="company_name"
                  value={formData.company_name}
                  onChange={handleChange}
                  fullWidth
                  style={{ marginRight: "8px" }}
                />
                <TextField
                  label="Position"
                  variant="outlined"
                  name="position"
                  select
                  value={formData.position}
                  onChange={handleChange}
                  fullWidth
                >
                  {[
                    "Mason",
                    "Helper",
                    "Electrician",
                    "Plumber",
                    "Painter",
                    "Driver",
                    "Tile Mason",
                    "Others",
                  ].map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            </Box>

            {/* Buttons */}
            <Box mt={4} display="flex" justifyContent="space-between">
              <Button
                type="button"
                variant="outlined"
                color="secondary"
                onClick={handleBack}
                style={{ flex: 1, marginRight: "8px" }}
              >
                Cancel
              </Button>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{ flex: 1 }}
                disabled={loading} // Disable button when loading
              >
                {loading ? (
                  <CircularProgress size={24} style={{ color: "white" }} /> // Display loading spinner
                ) : (
                  "Save"
                )}
              </Button>
            </Box>
          </form>
        </Container>
      </Container>
    </Layout>
  );
};

export default AddEmp;
