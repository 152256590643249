export const formatDate = (date) => {
  if (!date || date === "0000-00-00") {
    return; // Return undefined for null, undefined, or "0000-00-00"
  }

  const parsedDate =
    typeof date === "string"
      ? new Date(date.replace(/-/g, "/"))
      : new Date(date);

  if (isNaN(parsedDate.getTime())) {
    return; // Return undefined for invalid dates
  }

  const day = parsedDate.getDate().toString().padStart(2, "0");
  const month = parsedDate.toLocaleString("default", { month: "2-digit" });
  const year = parsedDate.getFullYear();

  return `${day}-${month}-${year}`; // Return formatted date
};

export const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return ""; // Return empty if no number is provided

  // Remove any non-numeric characters
  const numericPhoneNumber = phoneNumber.replace(/\D/g, "");

  // Identify the country code and extract the main number
  let mainNumber;
  if (
    numericPhoneNumber.startsWith("91") &&
    numericPhoneNumber.length === 12
  ) {
    mainNumber = numericPhoneNumber.substring(2); // Remove '91'
  } else if (
    numericPhoneNumber.startsWith("968") &&
    numericPhoneNumber.length === 11
  ) {
    mainNumber = numericPhoneNumber.substring(3); // Remove '968'
  } else {
    mainNumber = numericPhoneNumber; // No country code, use as is
  }

  // Format the main number
  let formattedNumber = "";
  if (mainNumber.length === 10) {
    formattedNumber = `${mainNumber.substring(0, 5)} ${mainNumber.substring(
      5
    )}`;
  } else if (mainNumber.length === 8) {
    formattedNumber = `${mainNumber.substring(0, 4)} ${mainNumber.substring(
      4
    )}`;
  } else {
    return phoneNumber; // Return as is if the length is unexpected
  }

  return formattedNumber;
};


export const formatExpiry = (expiry) => {
  if (!expiry || expiry === "0") return <span style={{ color: "grey" }}>N/A</span>; // If expiry is 0 or invalid, return N/A with default color
  
  const parts = expiry.split("-");
  
  if (parts.length < 2) return <span style={{ color: "grey" }}>N/A</span>; // Invalid format, return N/A with default color
  
  // Extract year and month from the expiry date
  return `${parts[1]}/${parts[0].slice(-2)}`; // Formats as MM/YY
};


export const formatCardNumber = (cardNumber) => {
  if (!cardNumber) return "N/A";
  // Remove any non-digit characters (just in case)
  cardNumber = cardNumber.replace(/\D/g, '');
  // Format in groups of 4 digits
  return cardNumber.replace(/(\d{4})(?=\d)/g, '$1 ');
};

export const getDateColor = (date) => {
  if (!date || date === "0000-00-00") {
    return { color: "grey", backgroundColor: "transparent" }; // Default style for invalid dates
  }

  const today = new Date();
  const parsedDate = new Date(date.replace(/-/g, "/"));

  if (isNaN(parsedDate)) {
    return { color: "grey", backgroundColor: "transparent" }; // Fallback for invalid date
  }

  const diffInDays = Math.ceil((parsedDate - today) / (1000 * 60 * 60 * 24));

  if (diffInDays <= 0) {
    return { color: "white", backgroundColor: "red" }; // Expired or today
  } else if (diffInDays <= 7) {
    return { color: "black", backgroundColor: "orange" }; // Within a week
  } else if (diffInDays <= 30) {
    return { color: "black", backgroundColor: "yellow" }; // Within a month
  } else if (diffInDays <= 90) {
    return { color: "black", backgroundColor: "lightgreen" }; // Within 3 months
  }

  return { color: "grey", backgroundColor: "transparent" }; // Default for dates beyond a month
};

export const getStatusLabel = (statusV) => {
  switch (statusV) {
    case "A":
      return "Active";
    case "D":
      return "Inactive";
    default:
      return "N/A";
  }
};

export const getStatusBackgroundColor = (statusValue) => {
  if (statusValue === "A") {
    return "#4CAF50";
  }
  if (statusValue === "D") {
    return "#FF5252";
  }
  return "transparent";
};
